import React, { lazy, useEffect} from "react";
import { Routes, Route, useNavigate, useLocation, Navigate} from "react-router-dom";
import Home from "./Pages/Home/Home";

const Customers = lazy(() => import("./Pages/Customers/Customers"));
const About = lazy(() => import("./Pages/About/About"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const Signup = lazy(() => import("./Pages/Signup/Signup"));
const Terms = lazy(() => import("./Pages/Terms/Terms"));
const Privacy = lazy(() => import("./Pages/Privacy/Privacy"));
//resource
const Views = lazy(()=> import("./Pages/Views/Views"));
const Features = lazy(()=> import("./Pages/Features/Features"));
const Alertnotification = lazy(()=> import("./Pages/Alertnotification/Alertnotification"));
const Workflow = lazy(()=> import("./Pages/Workflow/Workflow"));
const Documents = lazy(()=> import("./Pages/Documents/Documents"));
const Security = lazy(()=> import("./Pages/Security/Security")) ;
const Bianalytics = lazy(()=> import("./Pages/Bianalytics/Bianalytics"));
const Customizations = lazy(()=> import("./Pages/Customizations/Customizations"));
const BoardView = lazy(()=> import("./Pages/BoardView/BoardView"));
const ThankYou = lazy(() => import('./Pages/ThankYou/ThankYou'));
const Notfound = lazy(() => import("./components/NotFound/Notfound"));
//hr
const HrmsLayout = lazy(() => import("./module/Hrms/HrmsLayout/HrmsLayout"));
const HrmsHome = lazy(() => import("./module/Hrms/HrmsHome/HrmsHome"));

const CoreHrLayout = lazy(() => import("./module/Hrms/CoreHr/CoreHrLayout/CoreHrLayout"));
const CoreHrHome = lazy(() => import("./module/Hrms/CoreHr/CoreHrHome/CoreHrHome"));
const EmployeeDatabase = lazy(() => import("./module/Hrms/CoreHr/EmployeeDatabase/EmployeeDatabase"));
const EssPortal = lazy(() => import("./module/Hrms/CoreHr/EssPortal/EssPortal"));
const Document = lazy(() => import("./module/Hrms/CoreHr/Document/Document"));
const Workflows = lazy(() => import("./module/Hrms/CoreHr/Workflows/Workflows"));
const HrReports = lazy(() => import("./module/Hrms/CoreHr/HrReports/HrReports"));

const FieldForceLayout = lazy(() => import("./module/Hrms/FieldForce/FieldForceLayout/FieldForceLayout"));
const FieldForce = lazy(() => import("./module/Hrms/FieldForce/FieldForceHome/FieldForceHome"));

const AttendanceLayout = lazy(() => import("./module/Hrms/Attendance/AttendanceLayout/AttendanceLayout"));
const AttendanceHome = lazy(() => import("./module/Hrms/Attendance/AttendanceHome/AttendanceHome"));
const AttendanceManagement = lazy(() => import("./module/Hrms/Attendance/AttendanceManagement/AttendanceManagement"));
const LeaveManagement = lazy(() => import("./module/Hrms/Attendance/LeaveManagement/LeaveManagement"));
const ShiftManagement = lazy(() => import("./module/Hrms/Attendance/ShiftManagement/ShiftManagement"));

const PayrollLayout = lazy(() => import("./module/Hrms/Payroll/PayrollLayout/PayrollLayout"));
const PayrollHome = lazy(() => import("./module/Hrms/Payroll/PayrollHome/PayrollHome"));
// const SalarySlip = lazy(() => import("./module/Hrms/Payroll/SalarySlip/SalarySlip"));

const HrmsExpenseLayout = lazy(() => import("./module/Hrms/HrmsExpense/HrmsExpenseLayout/HrmsExpenseLayout"));
const HrmsExpenseHome = lazy(() => import("./module/Hrms/HrmsExpense/HrmsExpenseHome/HrmsExpenseHome"));

const HrmsTaskLayout = lazy(() => import("./module/Hrms/HrmsTasks/HrmsTaskLayout/HrmsTaskLayout"));
const HrmsTaskHome = lazy(() => import("./module/Hrms/HrmsTasks/HrmsTaskHome/HrmsTaskHome"));

const HrmsEngagementLayout = lazy(() => import("./module/Hrms/HrmsEngagement/HrmsEngagementLayout/HrmsEngagementLayout"));
const HrmsEngagementHome = lazy(() => import("./module/Hrms/HrmsEngagement/HrmsEngagementHome/HrmsEngagementHome"));

//blogs
// const BlogLayout = lazy(() => import("./module/Hrms/HrmsBlog/BlogLayout/BlogLayout"));
const BlogHome = lazy(()=> import("./module/Hrms/HrmsBlog/BlogHome/BlogHome"));
const BlogDetails = lazy(()=> import("./module/Hrms/HrmsBlog/BlogDetails/BlogDetails"));

// const HrmsPriceLayout = lazy(() => import("./module/Hrms/HrmsPricing/HrmsPriceLayout/HrmsPriceLayout"));
const HrmsPricing = lazy(() => import("./module/Hrms/HrmsPricing/HrmsPricing/HrmsPricing"));

//expense
const ExpenseLayout = lazy(()=> import("./module/Expense/ExpenseLayout/ExpenseLayout"));
const ExpenseHome = lazy(()=> import("./module/Expense/ExpenseHome/ExpenseHome"));
//sales
const SalesLayout = lazy(() => import("./module/Sales/SalesLayout/SalesLayout"));
const SalesHome = lazy(() => import("./module/Sales/SalesHome/SalesHome"));
const SalesFeauterLayout = lazy(() => import("./module/Sales/Feauters/SalesFeauterLayout/SalesFeauterLayout"));
const SalesManagement = lazy(() => import("./module/Sales/Feauters/SalesManagement/SalesManagement"));
const AccountsReceivable = lazy(()=> import("./module/Sales/Feauters/AccountsReceivable/AccountsReceivable"));
const BillingPayment = lazy(()=> import("./module/Sales/Feauters/BillingPayment/BillingPayment"));
const OrderExecution = lazy(()=> import("./module/Sales/Feauters/OrderExecution/OrderExecution"));
const Invoicving = lazy(()=> import("./module/Sales/Feauters/Invoicving/Invoicving"));
const TenderDeposit = lazy(()=> import("./module/Sales/Feauters/TenderDeposit/TenderDeposit"));
const SalesPerformance = lazy(()=> import("./module/Sales/Feauters/SalesPerformance/SalesPerformance"));
const DocumentManagement = lazy(()=> import("./module/Sales/Feauters/DocumentManagement/DocumentManagement"));
const BusinessManagement = lazy(()=> import("./module/Sales/Feauters/BusinessManagement/BusinessManagement"));

//qkrtask
const TaskHome = lazy(()=> import("./module/Qkrtask/TaskHome/TaskHome"));
const TaskLayout = lazy(()=> import("./module/Qkrtask/TaskLayout/TaskLayout"));
//qkrvisit
const VisitLayout = lazy(()=> import("./module/Visit/VisitLayout/VisitLayout"));
const VisitHome = lazy(()=> import("./module/Visit/VisitHome/VisitHome"));

//qkrdesk
const DeskHome = lazy(()=> import("./module/Desk/DeskHome/DeskHome"));
const DeskLayout = lazy(()=> import("./module/Desk/DeskLayout/DeskLayout"));
//qkrtenders
const TendersHome = lazy(()=> import("./module/Tenders/TendersHome/TendersHome"));
const TendersLayout = lazy(()=> import("./module/Tenders/TendersLayout/TendersLayout"));
//qkrproject
const ProjectHome = lazy(()=> import("./module/Project/ProjectHome/ProjectHome"));
const ProjectLayout = lazy(()=> import("./module/Project/ProjectLayout/ProjectLayout"));


const RemoveTrailingSlash = () => {

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.endsWith('/') && location.pathname !== '/') {
      navigate(location.pathname.slice(0, -1), { replace: true });
    }
  }, [location.pathname, navigate]);
  return null;
};

function AppRoutes() {

  return (
    <>
      <RemoveTrailingSlash />
      <Routes>
        <Route path="/" element={<Home />} /> 
        <Route path="/customers" element={<Customers />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/views" element={<Views />} />
        <Route path="/features" element={<Features />} />
        <Route path="/alertnotification" element={<Alertnotification />} />
        <Route path="/workflow" element={<Workflow />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/security" element={<Security />} />
        <Route path="/bianalytics" element={<Bianalytics />} />
        <Route path="/customizations" element={<Customizations />} />
        <Route path="/boardview" element={<BoardView />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/signup" element={<Signup />} />
        {/* blog */}
        {/* <Route path="/blogs/hrms-software" element={<BlogHome />} />
        <Route path="/blogs/hrms-software/:id" element={<BlogDetails />} /> */}
        <Route path="/hrms-software/qkrhr-blogs/" element={<BlogHome />} />
        <Route path="/hrms-software/:id" element={<BlogDetails />} />

        {/* only redirection use navigate */}
        <Route path="/blogs/hrms-software/top-hr-software-solutions-india-global" element={<Navigate to="/hrms-software/top-ten-best-hr-softwares-in-india-and-global" />}/>
       
        {/* price */}
        <Route path="/hrms-software/qkrhr-pricing" element={<HrmsPricing />}/>

        {/*Hrms Software */}
        <Route path="/hrms-software" element={<HrmsLayout />}>
          <Route index element={<HrmsHome />} />
          <Route element={<CoreHrLayout />}>
              <Route path="core-hr" element={<CoreHrHome />} />
              <Route path="employee-management" element={<EmployeeDatabase />} />
              <Route path="employee-self-service-portal" element={<EssPortal />} />
              <Route path="hr-document-management-system" element={<Document />} />
              <Route path="hr-workflow-management" element={<Workflows />} />
              <Route path="hr-data-analytics" element={<HrReports />} />
          </Route>

          <Route element={<AttendanceLayout />}>
            <Route path="leave-and-attendance-management" element={<AttendanceHome />} />
            <Route path="time-and-attendance-system" element={<AttendanceManagement />} />
            <Route path="leave-management-system" element={<LeaveManagement />} />
            <Route path="shift-management-system" element={<ShiftManagement />} /> 
          </Route>
          
          <Route element={<PayrollLayout />}>
            <Route path="payroll-management-system" element={<PayrollHome />} />
            {/* <Route path="salary-slip-generator" element={<SalarySlip />} />  */}
          </Route>

           <Route element={<FieldForceLayout />}>
            <Route index path="field-force-automation" element={<FieldForce />} />
          </Route>
          
          <Route element={<HrmsExpenseLayout />}>
              <Route index path="expense-management" element={<HrmsExpenseHome />} />
          </Route>

          <Route element={<HrmsTaskLayout />}>
              <Route index path="tasks" element={<HrmsTaskHome />} />
          </Route>

          <Route element={<HrmsEngagementLayout />}>
              <Route index path="employee-engagement" element={<HrmsEngagementHome />} />
          </Route>
         
          {/* <Route path="*" element={<Navigate to="/hrms-software" />}/> */}
        </Route>
      
        {/*QkrExpense */}
        <Route path="qkrexpense" element={<ExpenseLayout />}>
          <Route index  element={<ExpenseHome />} />
        </Route>

        {/*QkrDesk */}
        <Route path="qkrdesk" element={<DeskLayout />}>
            <Route index element={<DeskHome />} />
        </Route>
        
        {/*QkrSales */}
        <Route path="qkrsales" element={<SalesLayout />}>
          <Route index element={<SalesHome />} />
          <Route element={<SalesFeauterLayout />}>
            <Route index path="salesmanagement" element={<SalesManagement />} />
            <Route path="accountsreceivable" element={<AccountsReceivable />} />
            <Route path="billingpayment"  element={<BillingPayment />} />
            <Route path="orderexecution" element={<OrderExecution />} />
            <Route path="invoicing" element={<Invoicving />} />
            <Route path="tenderdeposit" element={<TenderDeposit />} />
            <Route path="salesperformance" element={<SalesPerformance />} />
            <Route path="documentmanagement" element={<DocumentManagement />} />
            <Route path="BusinessManagement" element={<BusinessManagement />} />
          </Route>
        </Route>

        {/*QkrTask*/}
        <Route path="qkrtasks" element={<TaskLayout />}>
            <Route index element={<TaskHome />} />
        </Route>

        {/*QkrVisit */}
        <Route path="qkrvisit" element={<VisitLayout />}>
          <Route index element={<VisitHome />} />
        </Route>

        {/*QkrTenders */}
        <Route path="qkrtenders" element={<TendersLayout />}>
            <Route index element={<TendersHome />} />
        </Route>

        {/*QkrProject */}
        <Route path="qkrprojects" element={<ProjectLayout />}>
            <Route index element={<ProjectHome />} />
        </Route>
        {/* blogs */}
        {/*<Route path="blogs" element={<BlogLayout />}>
            <Route index element={<BlogHome />} />
            <Route path=":id" element={<BlogDetails />} />
        </Route>*/}
        <Route path="*" element={<Notfound />} />

      </Routes>
      </>
  )
}

export default AppRoutes