import React,{useState, useEffect} from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom'; 
import { Col, Container, Row } from "react-bootstrap";
import ImagesUrl from "../../assets/Index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { SocialNetwork } from '../../components/SocialNetwork/SocialNetwork';
// import CookieConsent from '../../components/CookieConsent/CookieConsent';

function Footer() {
  const [isScrolled, setIsScrolled]=useState(false);

  const handelScrollTop = ()=>{
    window.scrollTo({top:0,behaviar:"smooth"});
  }

   useEffect (()=> {
    const handleScroll =()=> {
        setIsScrolled(window.scrollY >= 50);
     }
     window.addEventListener('scroll', handleScroll);

     return()=>{
      window.removeEventListener('scroll',handleScroll);
     }
   },[]);

    return (
        <>
           {/* <CookieConsent/> */}
            <footer className="footer-section">    
                  <div style={{display: isScrolled ? 'block':'none'}}>
                        <span className="bottomTop" onClick={handelScrollTop}><FontAwesomeIcon icon={faChevronUp}/></span>  
                  </div>
                  <Container>
                  <Row>
                      <Col lg="4">
                          <Link to="/" className="d-flex f-logo">
                            <div className="flex-shrink-0">
                                <img src={ImagesUrl.QkrbizLogo} alt="qkrbiz" />
                            </div>
                            <div className="flex-grow-1">
                                krbiz   
                            </div>
                          </Link>
                          <p className="mt-4">QkrBiz’s all-in-one software centralizes your business operations in a single platform. Tailored for small and mid-sized businesses, it simplifies HR, field tracking, sales, expenses, customer support, and tender management. Streamline every aspect of your business with ease.</p>
                          {/* <p className="mt-4">Leverage the expertise of industry veterans from IIT and IIM with over 30 years of experience to achieve greater success.</p> */}
                          <h5 className="mb-3 mt-1">Hexlog Infotech</h5>
                          <p className="mb-0">91-11-46522453</p>
                          <p><Link className='text-white' to="mailto: sales@qkrbiz.com">sales@qkrbiz.com</Link></p>

                          <SocialNetwork socialNetwork="social-network" />
                          {/* <ul className="list-unstyled social-network">
                            <li><a href="https://www.facebook.com/quikrbizERP" className="icoFacebook" title="Facebook"><FontAwesomeIcon icon={faFacebookF}/></a></li>
                            <li><a href="http://twitter.com/IntecPvt" className="icoTwitter" title="Twitter"><FontAwesomeIcon icon={faXTwitter}/></a></li>
                            <li><a href="http://www.linkedin.com/company/quikr-biz/" className="icoLinkedin" title="Linkedin"><FontAwesomeIcon icon={faLinkedinIn}/></a></li>
                          </ul>	                        */}
                          <div className="apps-link mt-4">
                              <a href="https://play.google.com/store/apps/details?id=com.intec.qkrbiz" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="d-flex play-store" 
                                style={{ marginRight: '1rem' }}>
                                <span className="flex-shrink-0">
                                  <img src={ImagesUrl.GooglePlay} alt="Google Play" />
                                </span>
                                <span className="flex-grow-1">
                                  <p>GET IT ON</p>
                                  <h5>GOOGLE PLAY</h5>
                                </span>
                              </a>

                              <a href="https://apps.apple.com/app/id1570623057" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="d-flex play-store">
                                <span className="flex-shrink-0">
                                  <img src={ImagesUrl.AppleIcon} alt="Apple Icon" />
                                </span>
                                <span className="flex-grow-1">
                                  <p>GET IT ON</p>
                                  <h5>APP STORE</h5>
                                </span>
                              </a>
                          </div>
                      </Col>
                      <Col lg="2" sm="6">
                          <h5>Products</h5>
                          <ul className="list-unstyled">
                            <li><Link to="/hrms-software">QkrHR</Link></li>
                            <li><Link to="/qkrvisit">Qkrvisit</Link></li>
                            <li><Link to="/qkrexpense">Qkrexpense</Link></li>
                            <li><Link to="/qkrdesk">Qkrdesk</Link></li>
                            <li><Link to="/qkrprojects">Qkrproject</Link></li>
                            <li><Link to="/qkrsales">Qkrsales</Link></li>
                            <li><Link to="/qkrtenders">Qkrtenders</Link></li>
                            <li><Link to="/qkrtasks">Qkrtasks</Link></li>
                          </ul>
                        </Col>
                        <Col lg="2" sm="6">
                        <div className="headings"><h5>More</h5></div>
                        <ul className="list-unstyled">
                          <li><Link to="/hrms-software/core-hr">Core HR</Link></li>
                          <li><Link to="/hrms-software/time-and-attendance-system">Time and Attendance</Link></li>
                          <li><Link to="/hrms-software/field-force-automation">Field Force Automation</Link></li>
                          <li><Link to="/hrms-software/expense-management">Expenses</Link></li>
                          <li><Link to="/hrms-software/payroll-management-system">Payroll</Link></li>
                          <li><Link to="/hrms-software/tasks">Tasks</Link></li>
                          <li><Link to="/qkrsales">Sales Order</Link></li>
                          <li><Link to="/qkrsales/salesmanagement">Sales Management</Link></li>
                          <li><Link to="/qkrsales/billingpayment">Billing & Payment Terms</Link></li>
                          <li><Link to="/qkrsales/salesperformance">Sales Performance</Link></li>
                          <li><Link to="/qkrsales/tenderdeposit">Tender Terms</Link></li>
                          <li><Link to="/qkrsales/invoicing">Invoicing</Link></li>
                        </ul>
                        </Col>
                        <Col lg="2" sm="6">
                        <div className="headings"> <h5>Features</h5></div>
                          <ul className="list-unstyled">
                            <li><Link to="/features">Spreadsheet Features</Link></li>
                            <li><Link to="/alertnotification">Alerts & Notifications</Link></li>
                            <li><Link to="/workflow">Workflow Automate </Link></li>
                            <li><Link to="/documents">Documents</Link></li>
                            <li><Link to="/security">Data Security</Link></li>
                            <li><Link to="/bianalytics">BI & Analytics</Link></li>
                            <li><Link to="/customizations">Customizations</Link></li>
                            {/* <li><Link to="/mobileapp">Mobile App</Link></li> */}
                            <li><Link to="/views">Views</Link></li>
                            <li><Link to="/boardview">Board View</Link></li>
                          </ul>
                        </Col>
                        <Col lg="2" sm="6">
                        <div className="headings"><h5>Company</h5></div>
                        <ul className="list-unstyled">
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/hrms-software/qkrhr-blogs">Blogs</Link></li>
                            <li><Link to="/customers">Clients</Link></li>
                            <li><Link to="/partner">Partner</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                            <li><Link to="/terms">Terms & Conditions</Link></li>
                            <li><Link to="/privacy">Privacy & Policy</Link></li>
                        </ul>
                        {/* <ThemedButton variant="primary" children="Primary Button"/>
                         <ThemedButton variant="secondary" children="Secondary Button"/> */}
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col lg="4">
                      </Col>
                      <Col lg="8">
                        <Row>
                            <Col lg="3">
                              <h5>Connect with us</h5>
                              <SocialNetwork socialNetwork="social-network" />
                            </Col>
                            <Col lg="4">
                              <h5>Contact Us</h5>
                              <ul className="list-unstyled">
                                  <li>Sales - <Link to="mailto: sales@qkrbiz.com">sales@qkrbiz.com</Link></li>
                                  <li>Support - <Link to="mailto: support@qkrbiz.com">support@qkrbiz.com</Link></li>
                              </ul>
                            </Col>
                            <Col lg="4">
                              <h5>Call Us On</h5>
                              <ul className="list-unstyled">
                                  <li>Sales - <Link to="mailto: sales@qkrbiz.com">91-11-46522453</Link></li>
                                  <li>(Mon - Fri 9:30 AM - 6:30 PM)</li>
                              </ul>
                            </Col>
                        </Row>
                      </Col>
                    </Row> */}
                  </Container>
            </footer>
        </>
    )
}

export default Footer