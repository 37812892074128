const ImagesUrl = {
	QkrbizLogo: require('./images/appicon/Qkrbiz.svg').default,
	QkrHRLogo: require('./images/appicon/QkrHR.svg').default,
	QkrbizLoader: require('./images/qkrbiz-loader.gif'),
	GooglePlay: require('./images/google-play.svg').default,
	AppleIcon: require('./images/apple.svg').default,
	HomeBannerImage: require('./images/home-banner.svg').default,
	HomeBottomImage: require('./images/home-bottom.svg').default,
	// BottomImage: require('./images/banner-bottm.svg').default,
	kiteIcon: require('./images/kiteIcon.svg').default,
	PlatformArrow: require('./images/platform-arow.svg').default,
	FreeTrailBG:require ('./images/FreeTrailBG.png'),
	FreeTrailGif:require ('./images/Free-Trail.gif'),
	Location:require ('./images/location-pin.png'),
	TickPink:require ('./images/tick-pink.svg').default,
	QkrbizOgurl: require('./images/qkrbiz-ogurl.png'),
	// SearchText:require('./images/SearchText.svg').default,
	platformfeatures:{
		PfpreadsheetFeatures:require('./images/platformFeature/PfSpreadsheetfeatures.svg').default,
		PfView:require('./images/platformFeature/PfViews.svg').default,
		PfAlertsNotification:require('./images/platformFeature/PfAlertsNotifications.svg').default,
		PfAutomationWorkflow:require('./images/platformFeature/PfAutomateworkflow.svg').default,
		PfDocuments:require('./images/platformFeature/PfDocuments.svg').default,
		PfSecurity:require('./images/platformFeature/PfSecurity.svg').default,
		PfBIAnalytics:require('./images/platformFeature/PfBIAnalytics.svg').default,
		PfCustomizations:require('./images/platformFeature/PfCustomizations.svg').default,
		PfMobileApp:require('./images/platformFeature/PfMobileApp.svg').default,
		PfBoards:require('./images/platformFeature/Pfboards.svg').default,
	},
	accountable :{
		SecureAccess: require ('./images/SecureAccess.svg').default,
		RoleBased: require ('./images/RoleBased.svg').default,
		ControlOver: require ('./images/ControlOver.svg').default,
		DataLevel: require ('./images/DataLevel.svg').default,
	},
	EffortlessExperience:{
		simplifiedBox: require('./images/SimplifiedBox.svg').default,
		familiarSpreadsheet: require('./images/FamiliarSpreadsheet.svg').default,
		clickAnywhere: require('./images/ClickAnywhere.svg').default,
		commandControl: require('./images/CommandControl.svg').default,
		employeeSelf: require('./images/EmployeeSelf.svg').default,
		customViews: require('./images/CustomViews.svg').default,
	},
	about:{
		OurTeamMember_1: require ('./images/OurTeamMember_1.png'),
		OurTeamMember_2: require ('./images/OurTeamMember_2.png'),
		OurTeamMember_3: require ('./images/OurTeamMember_3.png'),
		ClientsSay: require ('./images/ClientsSay.png'),
	},
	customers:{
		Customers: require ('./images/Customers.png'),
		customersheader: require ('./images/Customersheader.png'),
	},
	client:{
		ClientsImage1: require ('./images/client/iit-bhu.png'),
		ClientsImage2: require ('./images/client/swastik.png'),
		ClientsImage3: require ('./images/client/beltron.png'),
		ClientsImage4: require ('./images/client/bhanuprakash.png'),
		ClientsImage5: require ('./images/client/capitalpower.png'),
		ClientsImage6: require ('./images/client/dsk-enterprises.png'),
		ClientsImage7: require ('./images/client/nexgen.png'),
		ClientsImage8: require ('./images/client/hindustanherbal.png'),
		ClientsImage9: require ('./images/client/hitech.png'),
		ClientsImage10: require ('./images/client/iit_roorkee.png'),
		ClientsImage11: require ('./images/client/alacrit.png'),
		ClientsImage12: require ('./images/client/kde.png'),
		ClientsImage13: require ('./images/client/regard.png'),
		ClientsImage14: require ('./images/client/shalimarpaint.png'),
		ClientsImage15: require ('./images/client/skenterprises.png'),
		ClientsImage16: require ('./images/client/pousnyfood.png'),
		ClientsImage17: require ('./images/client/kingmaker.png'),
		ClientsImage18: require ('./images/client/kothari.jpg'),
		ClientsImage19: require ('./images/client/fire_solution.png'),
		ClientsImage20: require ('./images/client/raj-group.png'),
		ClientsImage21: require ('./images/client/anivet-helthcare.png'),
		ClientsImage22: require ('./images/client/visionplus.png')
	},
	clientDetail:{
		clientsAshok_jade :require ('./images/ashok_jade.png'),
		clientsDinesh_gupta :require ('./images/dinesh_gupta.png'),
		clientsRanjit_singh :require ('./images/ranjit_singh.png'),
		clientsAtal_vihari :require ('./images/atal_vihari.png'),
	},
	MultiTab:{
		SpreadSheets: require ('./images/feature/multitab/spreadSheets.png'),
		Boards: require ('./images/feature/multitab/boards.png'),
		FormView: require ('./images/feature/multitab/formView.png'),
		CardView: require ('./images/feature/multitab/cardView.png'),
		PivotTable: require ('./images/feature/multitab/pivotTable.png'),
		CalendarView: require ('./images/feature/multitab/calendarView.png'),
		Map: require ('./images/feature/multitab/map.png'),
		ListView: require ('./images/feature/multitab/listView.png'),
		Dashboard: require ('./images/feature/multitab/dashboard.png'),
		ChatView: require ('./images/feature/multitab/chatView.png'),
		Payroll:require ('./images/feature/multitab/Payroll.png'),
		EmployeeProfile:require('./images/feature/multitab/EmployeeProfile.jpg'),
	},
	TabScroll:{
		TabIconSpreadSheets: require ('./images/feature/TabScroll/TabIconSpreadSheets.svg').default,
		TabIconBoards: require ('./images/feature/TabScroll/TabIconBoards.svg').default,
		TabIconForm: require ('./images/feature/TabScroll/TabIconForm.svg').default,
		TabIconCard: require ('./images/feature/TabScroll/TabIconCard.svg').default,
		TabIconPivotTable: require ('./images/feature/TabScroll/TabIconPivotTable.svg').default,
		TabIconCalendar: require ('./images/feature/TabScroll/TabIconCalendar.svg').default,
		TabIconMap: require ('./images/feature/TabScroll/TabIconMap.svg').default,
		TabIconList: require ('./images/feature/TabScroll/TabIconList.svg').default,
		TabIconDashboard: require ('./images/feature/TabScroll/TabIconDashboard.svg').default,
		TabIconChat: require ('./images/feature/TabScroll/TabIconChat.svg').default,
		Tabspredsheet:require('./images/feature/TabScroll/tabspredsheet.png'),
		Tabboard:require('./images/feature/TabScroll/tabboard.png'),
		Tabcard:require('./images/feature/TabScroll/tabcard.jpg'),
		Tabpivottable:require('./images/feature/TabScroll/tabpivottable.png'),
		Tabmap:require('./images/feature/TabScroll/tabmap.png'),
		Tablist:require('./images/feature/TabScroll/tablist.png'),
		Tabdashboard:require('./images/feature/TabScroll/tabdashboard.png'),
		Tabcalendar:require('./images/feature/TabScroll/tabcalendar.png'),
		Tabchat:require('./images/feature/TabScroll/tabchat.png'),
		Tabform:require('./images/feature/TabScroll/tabform.png'),
	},
	view:{
		view_img: require ('./images/feature/View/view_img.png'),
		PayrollDashboard: require ('./images/feature/View/PayrollDashboard.png'),
		spreadsheets: require ('./images/feature/View/spreadsheets.png'),
		boardview: require ('./images/feature/View/boardview.png'),
		formview: require ('./images/feature/View/formview.png'),
		cardview: require ('./images/feature/View/cardview.png'),
		pivottables: require ('./images/feature/View/pivottables.png'),
		calendarview: require ('./images/feature/View/calendarview.png'),
		mapview: require ('./images/feature/View/mapview.png'),
		listview: require ('./images/feature/View/listview.png'),
		Dashboardview: require ('./images/feature/View/Dashboardview.png'),
	},
	feature:{
		arrows: require ('./images/feature/SpreadsheetFeatures/arrow.svg').default,
		ActionMenus: require ('./images/feature/SpreadsheetFeatures/ActionMenus.png'),
		Search: require ('./images/feature/SpreadsheetFeatures/Search.png'),
		Filter: require ('./images/feature/SpreadsheetFeatures/ColumnFilters.png'),
		ColumnFilters: require ('./images/feature/SpreadsheetFeatures/ColumnFilters.png'),
		PreconfiguredFilters:require ('./images/feature/SpreadsheetFeatures/PreconfiguredFilters.png'),
		Sort: require ('./images/feature/SpreadsheetFeatures/Sort.png'),
		MultiSort: require ('./images/feature/SpreadsheetFeatures/MultiSort.png'),
		FreezeColumns: require ('./images/feature/SpreadsheetFeatures/FreezeColumns.png'),	
		HideColumns: require ('./images/feature/SpreadsheetFeatures/HideColumns.png'),
		AddColumns: require ('./images/feature/SpreadsheetFeatures/AddColumns.png'),
		ResizeColumns: require ('./images/feature/SpreadsheetFeatures/ResizeColumns.png'),
		WrapText: require ('./images/feature/SpreadsheetFeatures/WrapText.png'),
		RearrangeColumns: require ('./images/feature/SpreadsheetFeatures/RearrangeColumns.png'),
		ViewColumnTotals: require ('./images/feature/SpreadsheetFeatures/ViewColumnTotals.png'),
		CustomViews: require ('./images/feature/SpreadsheetFeatures/CustomViews.png'),
		ExportExcel: require ('./images/feature/SpreadsheetFeatures/ExportExcel.png'),
		Zoom: require ('./images/feature/SpreadsheetFeatures/Zoom.png'),
		ResetColumnWidth: require ('./images/feature/SpreadsheetFeatures/ResetColumnWidth.png'),
		FullScreen: require ('./images/feature/SpreadsheetFeatures/FullScreen.png'),
		PivotTables: require ('./images/feature/SpreadsheetFeatures/PivotTables.png'),
		CardView: require ('./images/feature/SpreadsheetFeatures/CardView.png'),
		// PayrollSoftware:require ('https://d2w2i7rp1a0wob.cloudfront.net/static/images/home/payroll-software-keka.svg').default,
	},
	Alertnotification:{
		AlertsNotificationHeader: require ('./images/feature/AlertsNotifications/AlertsNotificationHeader.jpg'),
		Updates:require ('./images/feature/AlertsNotifications/Updates.png'),
		Reminders:require ('./images/feature/AlertsNotifications/Reminders.png'),
		MIS:require ('./images/feature/AlertsNotifications/MIS.png'),
		AlertsNotifications: require ('./images/feature/AlertsNotifications/AlertsNotification.png'),
		ConfigureRecipients: require ('./images/feature/AlertsNotifications/ConfigureRecipients.png'),
		EscalationAlerts: require ('./images/feature/AlertsNotifications/EscalationAlerts.png'),
		MISReports: require ('./images/feature/AlertsNotifications/MISReports.png'),
	},
	Document:{
		HocumentManagmentHeader:require('./images/feature/Document/HocumentManagmentHeader.png'),
		SharedDocuments: require ('./images/feature/Document/SharedDocuments.svg').default,
		TeamDocuments: require ('./images/feature/Document/TeamDocuments.svg').default,
		MyDocuments: require ('./images/feature/Document/MyDocuments.svg').default,
		CreateLetter: require ('./images/feature/Document/CreateLetters.png'),
		AllDocument: require ('./images/feature/Document/AllDocuments.png'),
		AccessDocuments: require ('./images/feature/Document/AccessDocuments.png'),
	},
	workflow:{
		AutomateWorkflowHeader:require ('./images/feature/Automateworkflow/AutomateWorkflowHeader.png'),
		EnhanceEfficiency:require ('./images/feature/Automateworkflow/EnhanceEfficiency.png'),
		ImproveDecision:require ('./images/feature/Automateworkflow/ImproveDecisionMaking.png'),
		EliminateErrors:require ('./images/feature/Automateworkflow/EliminateErrors.png'),
		ImproveProductivity:require ('./images/feature/Automateworkflow/ImproveProductivity.png'),
		SpecificallyHR:require ('./images/feature/Automateworkflow/SpecificallyHR.png'),
		RecurringBusiness:require ('./images/feature/Automateworkflow/StreamlineRecuring.png'),
		CustomizeForms:require ('./images/feature/Automateworkflow/CustomizeForms.svg').default,
		CustomizeNotification:require ('./images/feature/Automateworkflow/CustomizeNotification.svg').default,
		AttachDigital:require ('./images/feature/Automateworkflow/AttachDigital.svg').default,
		CentralizeApproval:require ('./images/feature/Automateworkflow/CentralizeApproval.png'),
		RemindersPending:require ('./images/feature/Automateworkflow/RemindersPending.svg').default,
		TrulyPaperLess:require ('./images/feature/Automateworkflow/TrulyPaperLess.svg').default,
		PendingApprovals:require ('./images/feature/Automateworkflow/PendingApprovals.png'),
		NotificationManage:require ('./images/feature/Automateworkflow/NotificationManage.png'),
		ApproveExpenses:require ('./images/feature/Automateworkflow/ApproveExpenses.png'),
		ApprovalWorkFlow:require ('./images/feature/Automateworkflow/ApprovalWorkFlow.png'),
	},
	BoardView:{
		BoardviewHead:require ('./images/feature/BoardView/BoardviewHead.png'),
		ActionMenus_b:require ('./images/feature/BoardView/ActionMenus_b.png'),
		Columns_b:require ('./images/feature/BoardView/Columns_b.png'),
		CustomFilters_b:require ('./images/feature/BoardView/CustomFilters_b.png'),
		DesignCards_b:require ('./images/feature/BoardView/DesignCards_b.png'),
		KanbanBoard_b:require ('./images/feature/BoardView/KanbanBoard_b.png'),
		MultipleBoard_b:require ('./images/feature/BoardView/MultipleBoard_b.png'),
		PreconfiguredFilters_b:require ('./images/feature/BoardView/PreconfiguredFilters_b.png'),
		ShareBoards_b:require ('./images/feature/BoardView/ShareBoards_b.png'),	
	},
	Security:{
		SecurityHeader: require ('./images/feature/Security/SecurityHeader.png'),
		DataPrivacy: require('./images/feature/Security/DataPrivacy.svg').default,
		RestrictedAccess: require('./images/feature/Security/RestrictedAccess.svg').default,
		DataSegregation: require('./images/feature/Security/DataSegregation.svg').default,
		NoLocalStorage: require('./images/feature/Security/NoLocalStorage.svg').default,
		SecureToken: require('./images/feature/Security/SecureToken.svg').default,
		PasswordPolicy:require('./images/feature/Security/PasswordPolicy.png'),
		Infrastructure: require ('./images/feature/Security/Infrastructure.png'),
		DataCenter: require ('./images/feature/Security/DataCenter.png'),
		PhysicalMonitoring: require('./images/feature/Security/PhysicalMonitoring.svg').default,
		ClimateControl: require('./images/feature/Security/ClimateControl.svg').default,
		SecureNetwork: require('./images/feature/Security/SecureNetwork.svg').default,
		AuthorizedPersonnel: require('./images/feature/Security/AuthorizedPersonnel.svg').default,
		EnhancedHardware: require('./images/feature/Security/EnhancedHardware.svg').default,
		DisasterRisk: require('./images/feature/Security/DisasterRisk.svg').default,
		HostedApplication:require('./images/feature/Security/HostedApplication.png'),
		NetworkLevel:require('./images/feature/Security/NetworkLevel.png'),
		IdentityAccess:require('./images/feature/Security/IdentityAccess.png'),
		InfrastructureLevel:require('./images/feature/Security/InfrastructureLevel.png'),
		DataSecurityArrow: require ('./images/feature/Security/DataSecurityArrow.svg').default,
	},
	custom:{
		CustomFields: require ('./images/feature/Customizations/CustomFields.png'),
		CustomAddonTab: require ('./images/feature/Customizations/CustomAddonTab.png'),
		CustomForms: require ('./images/feature/Customizations/CustomForms.png'),
		CustomTableViews: require ('./images/feature/Customizations/CustomTableViews.png'),
		CustomKanbanBoards: require ('./images/feature/Customizations/CustomKanbanBoards.png'),
		CustomListViews: require ('./images/feature/Customizations/CustomListViews.png'),

		CustomGenerateLetters: require ('./images/feature/Customizations/CustomGenerateLetters.png'),
		CustomMISReports: require ('./images/feature/Customizations/CustomMISReports.png'),
		CustomizeTemplates: require ('./images/feature/Customizations/CustomizeTemplates.png'),
		CustomColors: require ('./images/feature/Customizations/CustomColors.png'),
		CustomizeExpenseForm: require ('./images/feature/Customizations/CustomizeExpenseForm.png'),
		CustomRoles: require ('./images/feature/Customizations/CustomRoles.png'),
		CustomWorkflows: require ('./images/feature/Customizations/CustomWorkflows.png'),
		CustomPivotTables: require ('./images/feature/Customizations/CustomPivotTables.png'),
	},
    bIAnalytics:{
		actionable: require ('./images/feature/Bianalytics/ActionableInsights.svg').default,
		customizableDash: require ('./images/feature/Bianalytics/CustomizableDashboards.svg').default,
		interactive: require ('./images/feature/Bianalytics/Interactive.svg').default,
		intelligentAlerts: require ('./images/feature/Bianalytics/IntelligentAlerts.svg').default,
		customizableAna: require ('./images/feature/Bianalytics/CustomizableAnalytics.svg').default,
		colourReports: require ('./images/feature/Bianalytics/ColourReports.svg').default,
		BiHead: require ('./images/feature/Bianalytics/BiHead.png'),
		BiDashboard: require ('./images/feature/Bianalytics/BiDashboard.png'),
		Ring: require ('./images/feature/Bianalytics/Ring.png'),
		RingDesign: require ('./images/feature/Bianalytics/RingDesign.svg').default,
		ActionableInsights: require ('./images/feature/Bianalytics/ActionableInsights.png'),
		EnhanceVisibility: require ('./images/feature/Bianalytics/EnhanceVisibility.png'),
		IntelligentAlerts: require ('./images/feature/Bianalytics/IntelligentAlerts.png'),
		Interactiveisualizations: require ('./images/feature/Bianalytics/Interactiveisualizations.png'),
	},
	// moduleapp:{
	// 	MobileApp : require ('./images/feature/MobileApp/MobileApp.png')
	// },
	ListView:{
		Listview: require ('./images/feature/ListView/listview.png'),
		Configured: require ('./images/feature/ListView/Configured.svg').default,
	},
	contact:{
	   Address: require('./images/contact/address.svg').default,
	   Call: require('./images/contact/call.svg').default,
	   Email: require('./images/contact/email.svg').default,
	   Hours: require('./images/contact/hours.svg').default,
	},
	IntelligentSystem:{
		ExpenseManagement: require ('./images/feature/IntelligentSystem/ExpenseManagement.svg').default,
		ApprovalWorkflows: require ('./images/feature/IntelligentSystem/ApprovalWorkflows.svg').default,
		FieldVisits: require ('./images/feature/IntelligentSystem/FieldVisits.svg').default,
		IntelligentDashboards: require ('./images/feature/IntelligentSystem/IntelligentDashboards.svg').default,
		ProjectExpenses: require ('./images/feature/IntelligentSystem/ProjectExpenses.svg').default,
		TravelManagement: require ('./images/feature/IntelligentSystem/TravelManagement.svg').default,
    },
	video:{
		FreezeColumns:require('./images/video/FreezeColumns.mp4'),
		HomeBannerVector:require('./images/video/HomeBannerVector.webm'),
		AttendenceVedio:require('./images/video/AttendenceVideo.mp4'),
		Mobilepolicies:require('./images/video/MobilePolicy.mp4'),
		EmpProfile:require('./images/video/EmpProfile.mp4'),
		CoreHRHeader:require('./images/video/CoreHRHeader.mp4'),
		GenerateLetter:require('./images/video/GenerateLetters.mp4'),
		TeamLocation:require('./images/video/TeamLocation.mp4'),
		TeamLocationHr:require('./images/video/TeamLocationHr.mp4'),
		ExpensesHeader:require('./images/video/ExpensesHeader.mp4'),
		BiometricIntegration:require('./images/video/BiometricIntegration.mp4'),
		AutomatedProces:require('./images/video/AutomatedProces.mp4'),
		FieldForceAutomationHeader:require('./images/video/FieldForceAutomationHeader.mp4'),	
		ProjectExpenses:require('./images/video/ProjectExpenses.mp4'),
		ExpenseAnalytics:require('./images/video/ExpenseAnalytics.mp4'),
		FieldForceAnalytics:require('./images/video/FieldForceAnalytics.mp4'),
		AlertNotification:require('./images/video/AlertNotification.mp4'),
		AlertNotificationRepeat:require('./images/video/AlertNotificationRepeat.mp4'),
		EscalationAlert:require('./images/video/EscalationAlerts.mp4'),
		TimeAttendanceHeaders:require('./images/video/TimeAttendanceHeader.mp4'),
		HRPayroll:require('./images/video/HRPayroll.mp4'),
		AutomateWorkflowHeader:require('./images/video/AutomateWorkflowHeader.mp4'),
		QkrdeskHearders:require('./images/video/QkrdeskHearders.mp4'),
		Helpdesk:require('./images/video/Helpdesk.mp4'),
		HelpdeskAnimation:require('./images/video/HelpdeskAnimation.mp4'),
		TasksHeader:require('./images/video/TasksHeader.mp4'),
		DocumentsHeader:require('./images/video/DocumentsHeader.mp4'),
		TabsSpredsheet:require('./images/video/TabsSpredsheet.mp4'),
		TabsCards:require('./images/video/TabsCards.mp4'),
		SalesVisitsVideo: require ('./images/video/SalesVisits.mp4'),
		ProjectVisits: require ('./images/video/ProjectVisits.mp4'),
	},
	// hrms
	hrms:{
		hrmsHeader: require('./images/hrms/hrms-header.png'),
		hrmsAttendance: require('./images/hrms/Attendance.png'),
		hrmsEmployeeProfile: require('./images/hrms/employee-profile.png'),
		hrmsMap: require ('./images/hrms/hrms-map.png'),
		hrmsExpense: require('./images/hrms/expense-anagement.png'),
		hrmsPayroll: require('./images/hrms/hrms-payroll.png'),
		supercharge: require('./images/hrms/supercharge.png'),
		WhatMakesMobile:require ('./images/hrms/what-makes-mobile.png'),
		//mobile screen
		hrmsEmployeeProDesktope: require ('./images/hrms/hrms-employeePro-desktop.png'),
		hrmsEmployeeProMobile: require ('./images/hrms/hrms-employeePro-mobile.png'),
		hrmsParollDesktop: require ('./images/hrms/hrms-paroll-desktop.png'),
		hrmsParollMobile: require ('./images/hrms/hrms-paroll-mobile.png'),
		hrmsAttendanceDesktop: require ('./images/hrms/hrms-attendance-desktop.png'),
		hrmsAttendanceMobile: require ('./images/hrms/hrms-attendance-mobile.png'),
		hrmsFieldForceDesktop: require ('./images/hrms/hrms-fieldForce-desktop.png'),
		hrmsFieldForceMobile: require ('./images/hrms/hrms-fieldForce-mobile.png'),
	},
	coreHr:{
		coreHrHeader: require('./images/hrms/corehr/coreHrHeader.jpg'),
		coreHrPge: require('./images/hrms/corehr/coreHrPge.png'),
		employeeInformation: require('./images/hrms/corehr/employeeInformation.svg').default,
		reportsInsights: require('./images/hrms/corehr/reportsInsights.svg').default,
		attendanceTracking: require('./images/hrms/corehr/attendanceTracking.svg').default,
		automateHRWorkflow: require('./images/hrms/corehr/automateHRWorkflow.svg').default,
		manageCompliance: require('./images/hrms/corehr/manageCompliance.svg').default,
	},
	employeeDb:{
		employeeHeader:require('./images/hrms/corehr/employeeDb/employee-database-header.png'),
		kanbanFullFinal:require('./images/hrms/corehr/employeeDb/kanbanFullFinal.png'),
		kanbanrecRuitment:require('./images/hrms/corehr/employeeDb/kanbanrecRuitment.png'),
		kanbanEmpTranstion:require('./images/hrms/corehr/employeeDb/kanbanEmpTranstion.png'),
		employeeProfile: require('./images/hrms/corehr/employeeDb/employeeProfile.png'),
		orgStructure: require('./images/hrms/corehr/employeeDb/orgStructure.png'),
		employeeDirectory: require('./images/hrms/corehr/employeeDb/employeeDirectory.png'),
		orgQuickLinks: require('./images/hrms/corehr/employeeDb/orgQuickLinks.png'),
		hrPolicies: require('./images/hrms/corehr/employeeDb/hrPolicies.png'),
		rolesPermissions: require('./images/hrms/corehr/employeeDb/rolesPermissions.png'),
		assetTracking: require('./images/hrms/corehr/employeeDb/assetTracking.png'),
		certificateManagement: require('./images/hrms/corehr/employeeDb/certificateManagement.png'),
		auditTrail: require('./images/hrms/corehr/employeeDb/auditTrail.png'),
		employeeService: require('./images/hrms/corehr/employeeDb/employeeService.png'),
		documentManagement:require('./images/hrms/corehr/employeeDb/document-management.png'),
		employeeEngagement:require ('./images/hrms/corehr/employeeDb/employeeEngagement.png'),
	},
	// employeeDatabase:{
	// 	employeeHeader:require('./images/hrms/corehr/employeeDatabase/employee-database-header.svg').default,
	// 	kanbanFullFinal:require('./images/hrms/corehr/employeeDatabase/kanbanFullFinal.png'),
	// 	kanbanrecRuitment:require('./images/hrms/corehr/employeeDatabase/kanbanrecRuitment.png'),
	// 	kanbanEmpTranstion:require('./images/hrms/corehr/employeeDatabase/kanbanEmpTranstion.png'),
	// 	employeeData: require('./images/hrms/corehr/employeeDatabase/EmployeeDatabase.svg').default,
	// 	organizationStructure: require('./images/hrms/corehr/employeeDatabase/OrganizationStructure.svg').default,
	// 	employeeDirectory: require('./images/hrms/corehr/employeeDatabase/EmployeeDirectory.svg').default,
	// 	organizationQuickLinks: require('./images/hrms/corehr/employeeDatabase/OrganizationQuickLinks.svg').default,
	// 	documentManagement: require('./images/hrms/corehr/employeeDatabase/DocumentManagement.svg').default,
	// 	hrPolicies: require('./images/hrms/corehr/employeeDatabase/HRPolicies.svg').default,
	// 	rolesPermissions: require('./images/hrms/corehr/employeeDatabase/RolesPermissions.svg').default,
	// 	assetTracking: require('./images/hrms/corehr/employeeDatabase/AssetTracking.svg').default,
	// 	certificationManagement: require('./images/hrms/corehr/employeeDatabase/CertificationManagement.svg').default,
	// 	auditTrail: require('./images/hrms/corehr/employeeDatabase/AuditTrail.svg').default,
	// 	employeeSelfService: require('./images/hrms/corehr/employeeDatabase/EmployeeSelfService.svg').default,
	// 	employeeEngagement: require('./images/hrms/corehr/employeeDatabase/EmployeeEngagement.svg').default,
	// 	notificationManage: require('./images/hrms/corehr/employeeDatabase/notificationManage.svg').default,
	// },
	essPortal: {
		essPortalHearder: require('./images/hrms/corehr/essPortal/essPortalHearder.png'),
		personalInfo: require('./images/hrms/corehr/essPortal/personalInfo.png'),
		trackAttendance: require('./images/hrms/corehr/essPortal/trackAttendance.png'),
		viewPayrollInfo: require('./images/hrms/corehr/essPortal/viewPayrollInfo.png'),
		leaveDashboard: require('./images/hrms/corehr/essPortal/leaveDashboard.png'),
		reportingHierarchy: require('./images/hrms/corehr/essPortal/reportingHierarchy.png'),
		accessPaySlip: require('./images/hrms/corehr/essPortal/accessPaySlip.png'),
	},
	hrDocument:{
		employeeRelatedDoc: require('./images/hrms/corehr/hrDocument/employeeRelatedDoc.png'),
		generateLetters: require('./images/hrms/corehr/hrDocument/generateLetters.png'),
		eSignatures: require('./images/hrms/corehr/hrDocument/eSignatures.png'),
		offerLetter: require('./images/hrms/corehr/hrDocument/offerLetter.png'),
		customForms: require('./images/hrms/corehr/hrDocument/customForms.png'),
	},
	hrWorkflow:{
		hrAutomateWorkflow: require('./images/hrms/corehr/hrWorkflow/hrAutomateWorkflow.png'),
		approvals: require('./images/hrms/corehr/hrWorkflow/approvals.png'),
		hrWorkflows: require('./images/hrms/corehr/hrWorkflow/hrWorkflows.png'),
		alertsNotifications : require ('./images/hrms/corehr/hrWorkflow/alertsNotifications.png'),
		// certificationManagement : require ('./images/hrms/corehr/hrWorkflow/certificationManagement.png'),
	},
	hrReports:{
		hrReportsHeader: require('./images/hrms/corehr/hrReports/hr-reports-header.png'),
		visitReports: require('./images/hrms/corehr/hrReports/visitReports.png'),
		leaveLedgerReport: require('./images/hrms/corehr/hrReports/leaveLedgerReport.png'),
		movementsRecord: require('./images/hrms/corehr/hrReports/movementsRecord.png'),
		employeeAtteSheet: require('./images/hrms/corehr/hrReports/employeeAtteSheet.png'),
		payrollReports: require('./images/hrms/corehr/hrReports/payroll-reports.png'),
		
	},
	fieldForceAuto:{
		fieldForceHeader:require('./images/hrms/fieldForceAutomation/fieldForceHeader.jpg'),
		gpsMobileTracking : require ('./images/hrms/fieldForceAutomation/gps-mobile-attendance.png'),
		fieldVisits : require ('./images/hrms/fieldForceAutomation/field-visits.png'),
		liveTeamLocation : require ('./images/hrms/fieldForceAutomation/live-team-location.png'),
		selfieWithPunch : require ('./images/hrms/fieldForceAutomation/selfie-with-punch.png'),
		salesVisits : require ('./images/hrms/fieldForceAutomation/sales-visits.png'),
		serviceVisits : require ('./images/hrms/fieldForceAutomation/service-visits.png'),
		projectVisits : require ('./images/hrms/fieldForceAutomation/project-visits.png'),
		Mileages: require ('./images/hrms/fieldForceAutomation/Mileages.png'),
		movementReports : require ('./images/hrms/fieldForceAutomation/movement-reports.png'),
		customerLocations : require ('./images/hrms/fieldForceAutomation/customer-locations.png'),
		geofencing: require ('./images/hrms/fieldForceAutomation/geo-fencing.png'),
		minimizeDeviations : require ('./images/hrms/fieldForceAutomation/minimize-deviations.png'),
		indepthReporting : require ('./images/hrms/fieldForceAutomation/indepth-reporting.png'),
		fieldForceAnalytics: require ('./images/hrms/fieldForceAutomation/field-force-analytics.jpg'),
		supervisorManagedAttendance : require ('./images/hrms/fieldForceAutomation/supervisor-managed-attendance.png'),
		instantDocuments : require ('./images/hrms/fieldForceAutomation/instant-documents.png'),
		onSiteTeam : require ('./images/hrms/fieldForceAutomation/on-site-team.png'),
		mobilePolicy:require('./images/hrms/fieldForceAutomation/mobile-policy.jpg'),
		configurableMobilePolicies:require('./images/hrms/fieldForceAutomation/configurable-policies.png'),
	},
	timeAttendance:{
		timeAttendanceHeader : require ('./images/hrms/timeAttendance/time-Attendance-header.png'),
	},
	AttendanceManagement:{
		attendanceManagementHeader : require ('./images/hrms/timeAttendance/attendanceManagement/attendance-management-header.png'),
		attendancePolicies : require ('./images/hrms/timeAttendance/attendanceManagement/attendance-policies.png'),
		attendanceCalendar : require ('./images/hrms/timeAttendance/attendanceManagement/attendance-calendar.png'),
		overtimeCalculations : require ('./images/hrms/timeAttendance/attendanceManagement/overtime-calculations.png'),
		payrollAutomation : require ('./images/hrms/timeAttendance/attendanceManagement/payroll-automation.png'),
		attendanceRegularization : require ('./images/hrms/timeAttendance/attendanceManagement/attendance-regularization.png'),
		deviationPolicy : require ('./images/hrms/timeAttendance/attendanceManagement/deviation-policy.png'),
		supervisorManaged: require ('./images/hrms/timeAttendance/attendanceManagement/supervisor-managed.png'),
		bluCollarWorkers: require ('./images/hrms/timeAttendance/attendanceManagement/blue-collar-workers.png'),
		biometricIntegration : require ('./images/hrms/timeAttendance/attendanceManagement/biometric-integration.svg').default,
		gpsMobileTracking : require ('./images/hrms/timeAttendance/attendanceManagement/gps-mobile-tracking.png'),
	},
	leaveManagement:{
		leaveManagementHeader : require ('./images/hrms/timeAttendance/leaveManagent/leave-management-header.png'),
		leavePolicies : require ('./images/hrms/timeAttendance/leaveManagent/leave-policies.png'),
		applyLeaves : require ('./images/hrms/timeAttendance/leaveManagent/apply-leaves.png'),
		leaveBalance : require ('./images/hrms/timeAttendance/leaveManagent/leave-balance.png'),
		leaveApproval : require ('./images/hrms/timeAttendance/leaveManagent/leave-approval.png'),
		leavePayrollCalculations : require ('./images/hrms/timeAttendance/leaveManagent/payroll-calculations.png'),
		probationNoticePeriod : require ('./images/hrms/timeAttendance/leaveManagent/probation-noticePeriod.png'),
		holidayCalendars : require ('./images/hrms/timeAttendance/leaveManagent/holiday-calendars.png'),
		compOffs : require ('./images/hrms/timeAttendance/leaveManagent/comp-offs.png'),
		teamLeaveCalendar : require ('./images/hrms/timeAttendance/leaveManagent/team-leave-calendar.png'),
	},
	shiftManagements:{
		shiftManagementHeader : require ('./images/hrms/timeAttendance/shiftManagement/shift-management-hearder.png'),
		shiftManagement : require ('./images/hrms/timeAttendance/shiftManagement/shift-management.png'),
		shiftRosters : require ('./images/hrms/timeAttendance/shiftManagement/shift-rosters.png'),
		shiftCalendar : require ('./images/hrms/timeAttendance/shiftManagement/shift-calendar.png'),
	},
	payRolls:{
		payrollHeader : require ('./images/hrms/payrolls/PayrollHeader.jpg'),
		configurableRules : require ('./images/hrms/payrolls/ConfigurableRules.png'),
		singleConsole : require ('./images/hrms/payrolls/SingleConsole.png'),
		generateBankLetters : require ('./images/hrms/payrolls/GenerateBankLetters.png'),
		easyloanTrackingEmi : require ('./images/hrms/payrolls/EasyloanTrackingEmi.png'),
		revisingSalary : require ('./images/hrms/payrolls/RevisingSalary.png'),
		employeeCentric : require ('./images/hrms/payrolls/EmployeeCentric.png'),
		keepTtrackRevision : require ('./images/hrms/payrolls/KeepTtrackRevision.png'),
		statutoryCompliances: require ('./images/hrms/payrolls/StatutoryCompliances.png'),
	},
	employeeEngagement:{
		engagementHeaders: require ('./images/hrms/employeeEngagement/engagement-headers.png'),
		empAnnouncement:require ('./images/hrms/employeeEngagement/emp-announcement.png'),
		feeds:require ('./images/hrms/employeeEngagement/emp-feeds.png'),
		praise:require ('./images/hrms/employeeEngagement/emp-praise.png'),
		qkrbizPoll: require ('./images/hrms/employeeEngagement/emp-poll.png'),
		qkrbizFeedback: require ('./images/hrms/employeeEngagement/emp-feedback.png'),
		qkrbizInternalNotes: require ('./images/hrms/employeeEngagement/emp-internal-notes.png'),
		qkrbizChat: require ('./images/hrms/employeeEngagement/emp-chat.png'),
	},
	blogs:{
		bestHrSoftware: require ('./images/hrms/blogs/best-hr-software.png'),
		TypesofHRSoftware : require ('./images/hrms/blogs/types-of-hr-software.jpg'),
		trendsHRTechnology: require ('./images/hrms/blogs/trends-HR-technology.png'),
		generateSalarySlips: require ('./images/hrms/blogs/generate-salary slips-online.png'),
		blogBookFree: require('./images/hrms/blogs/blog-book-free.png'),
		bestHRSoftwareIndiaQkrhr: require('./images/hrms/blogs/best-hr-software-India-qkrhr.png'),
	},
	//hr
	// hr:{ 
	// 	QkrHrBg: require ('./images/HR/QkrHrBg.png'),
	// 	QkrHrHeader: require ('./images/HR/QkrHrHeader.png'),
	// 	hrms : require ('./images/HR/Hrms.png'),
	// 	TabHrmsBg: require ('./images/HR/HrmsBg.png'),
	//     //Core HR
	// 	 CoreHrBG: require ('./images/HR/CoreHr/CoreHrBG.png'),
	// 	 CoreHRHeader : require ('./images/HR/CoreHr/CoreHRHeader.jpg'),
	// 	//  fullFinal: require ('./images/HR/CoreHr/fullFinal.png'),
	// 	//  recruitment: require ('./images/HR/CoreHr/recruitment.png'),
	// 	//  employeeTranstion: require ('./images/HR/CoreHr/employeeTranstion.png'),
	// 	 EmployeeProfiles : require ('./images/HR/CoreHr/EmployeeProfiles.png'),
	// 	 Documents : require ('./images/HR/CoreHr/Documents.png'),
	// 	 HRPolicies : require ('./images/HR/CoreHr/HRPolicies.png'),
	// 	 RolesPermissions : require ('./images/HR/CoreHr/RolesPermissions.png'),
	// 	 GenerateLetters : require ('./images/HR/CoreHr/GenerateLetters.jpg'),
	// 	 AssetTracking : require ('./images/HR/CoreHr/AssetTracking.png'),
	// 	 HRWorkflows : require ('./images/HR/CoreHr/HRWorkflows.png'),
	// 	 AlertsNotifications : require ('./images/HR/CoreHr/AlertsNotifications.png'),
	// 	 CertificationManagement : require ('./images/HR/CoreHr/CertificationManagement.png'),
	// 	 Approvals : require ('./images/HR/CoreHr/Approvals.png'),
	// 	 OrganizationStructure : require ('./images/HR/CoreHr/OrganizationStructure.png'),
	// 	 EmployeeDirectory : require ('./images/HR/CoreHr/EmployeeDirectory.png'),
	// 	 AutitTrial : require ('./images/HR/CoreHr/auditTrial.png'),
	// 	 DigitalSignatures:require ('./images/HR/CoreHr/DigitalSignatures.png'),
	// 	 OrganizationQuick:require ('./images/HR/CoreHr/OrganizationQuick.png'),
	// 	 OfferLetter:require ('./images/HR/CoreHr/OfferLetter.png'),
	// 	 SalaryConfig:require ('./images/HR/CoreHr/SalaryConfig.png'),
	// 	 CustomForm:require ('./images/HR/CoreHr/CustomForm.png'),	
	//    //Field Force
	//      FieldForceHeader : require ('./images/HR/FieldForce/FieldForceHeader.png'),
	// 	 FieldForceAutomationHeader: require ('./images/HR/FieldForce/FieldForceAutomationHeader.jpg'),
    //      GPSMobileAttendance : require ('./images/HR/FieldForce/GPSMobileAttendance.png'),
	// 	 FieldVisits : require ('./images/HR/FieldForce/FieldVisits.png'),
	// 	 LiveTeamLocation : require ('./images/HR/FieldForce/LiveTeamLocation.png'),
	// 	 LiveTracking : require ('./images/HR/FieldForce/LiveTracking.png'),
	// 	 SelfieWithPunch : require ('./images/HR/FieldForce/SelfieWithPunch.png'),
	// 	 SalesVisits : require ('./images/HR/FieldForce/SalesVisits.png'),
	// 	 ServiceVisits : require ('./images/HR/FieldForce/ServiceVisits.png'),
	// 	 ProjectVisits : require ('./images/HR/FieldForce/ProjectVisits.png'),
	// 	 Mileage : require ('./images/HR/FieldForce/Mileage.png'),
	// 	 MovementReports : require ('./images/HR/FieldForce/MovementReports.png'),
	// 	 CustomerLocations : require ('./images/HR/FieldForce/CustomerLocations.png'),
	// 	 Geofencing : require ('./images/HR/FieldForce/Geofencing.png'),
	// 	 MinimizeDeviations : require ('./images/HR/FieldForce/MinimizeDeviations.png'),
	// 	 InDepthReporting : require ('./images/HR/FieldForce/InDepthReporting.png'),
	// 	 FieldForceAnalytics: require ('./images/HR/FieldForce/FieldForceAnalytics.jpg'),
	// 	 InstantDocuments : require ('./images/HR/FieldForce/InstantDocuments.png'),
	// 	 OnSiteTeam : require ('./images/HR/FieldForce/OnSiteTeam.png'),
	// 	 SupervisorManagedAttendance : require ('./images/HR/FieldForce/SupervisorManagedAttendance.png'),
	// 	 MobilePolicy:require('./images/HR/FieldForce/MobilePolicy.jpg'),
	// 	 ConfigurableMobilePolicies:require('./images/HR/FieldForce/ConfigurableMobilePolicies.png'),
	// 	 //Attendance
	// 	 TimeAttendanceHeader : require ('./images/HR/Attendance/TimeAttendanceHeader.png'),
	// 	 AttendancePolicies : require ('./images/HR/Attendance/AttendancePolicies.png'),
	// 	 AttendanceCalendar : require ('./images/HR/Attendance/AttendanceCalendar.png'),
	// 	 TeamLeaveCalendar : require ('./images/HR/Attendance/TeamLeaveCalendar.png'),
	// 	 BiometricIntegration : require ('./images/HR/Attendance/BiometricIntegration.png'),
	// 	 GPSMobileTracking : require ('./images/HR/Attendance/GPSMobileTracking.png'),
	// 	 PayrollAutomation : require ('./images/HR/Attendance/PayrollAutomation.png'),
	// 	 OvertimeCalculations : require ('./images/HR/Attendance/OvertimeCalculations.png'),
	// 	 AttendanceRegularization : require ('./images/HR/Attendance/AttendanceRegularization.png'),
	// 	 CompOffs : require ('./images/HR/Attendance/CompOffs.png'),
	// 	 HolidayCalendars : require ('./images/HR/Attendance/HolidayCalendars.png'),
	// 	 ShiftManagement : require ('./images/HR/Attendance/ShiftManagement.png'),
	// 	 DeviationPolicy : require ('./images/HR/Attendance/DeviationPolicy.png'),
	// 	 ShiftRosters : require ('./images/HR/Attendance/ShiftRosters.png'),
	// 	 ShiftCalendar : require ('./images/HR/Attendance/ShiftCalendar.png'),
	// 	 LeavePolicies : require ('./images/HR/Attendance/LeavePolicies.png'),
	// 	 ApplyLeaves : require ('./images/HR/Attendance/ApplyLeaves.png'),
	// 	 EmployeeSelfService : require ('./images/HR/Attendance/EmployeeSelfService.png'),
	// 	 LeaveApproval : require ('./images/HR/Attendance/LeaveApproval.png'),
	// 	 LeavePayrollCalculations : require ('./images/HR/Attendance/LeavePayrollCalculations.png'),
	// 	 ProbationNoticePeriod : require ('./images/HR/Attendance/ProbationNoticePeriod.png'),
	// 	 LeaveBalance : require ('./images/HR/Attendance/LeaveBalance.png'),
	// 	 //paroll
	// 	 PayrollHeader : require ('./images/HR/Payroll/PayrollHeader.jpg'),
	// 	 ConfigurableRules : require ('./images/HR/Payroll/ConfigurableRules.png'),
	// 	 SingleConsole : require ('./images/HR/Payroll/SingleConsole.png'),
	// 	 GenerateBankLetters : require ('./images/HR/Payroll/GenerateBankLetters.png'),
	// 	 EasyloanTrackingEmi : require ('./images/HR/Payroll/EasyloanTrackingEmi.png'),
	// 	 RevisingSalary : require ('./images/HR/Payroll/RevisingSalary.png'),
	// 	 EmployeeCentric : require ('./images/HR/Payroll/EmployeeCentric.png'),
	// 	 KeepTtrackRevision : require ('./images/HR/Payroll/KeepTtrackRevision.png'),
	// 	 StatutoryCompliances: require ('./images/HR/Payroll/StatutoryCompliances.png'),
	// 	 //mobile screen
	// 	 QKrbizEmployeeProDesktope: require ('./images/HR/CoreHr/QKrbizEmployeePro-desktop.png'),
	// 	 QKrbizEmployeeProMobile: require ('./images/HR/CoreHr/QKrbizEmployeePro-mobile.png'),
	// 	 QKrbizParollDesktop: require ('./images/HR/Payroll/QKrbizParoll-desktop.png'),
	// 	 QKrbizParollMobile: require ('./images/HR/Payroll/QKrbizParoll-mobile.png'),
	// 	 QKrbizAttendanceDesktop: require ('./images/HR/Attendance/QKrbizAttendance-desktop.png'),
	// 	 QKrbizAttendanceMobile: require ('./images/HR/Attendance/QKrbizAttendance-mobile.png'),
	// 	 QKrbizFieldForceDesktop: require ('./images/HR/FieldForce/QKrbizFieldForce-desktop.png'),
	// 	 QKrbizFieldForceMobile: require ('./images/HR/FieldForce/QKrbizFieldForce-mobile.png'),
	// 	//Engagement
	// 	EngagementHeader: require ('./images/HR/Engagement/EngagementHeader.png'),
	// 	Announcement:require ('./images/HR/Engagement/Announcement.png'),
	// 	Feeds:require ('./images/HR/Engagement/Feeds.png'),
	// 	Praise:require ('./images/HR/Engagement/Praise.png'),
	// 	QKrbizPoll: require ('./images/HR/Engagement/Poll.png'),
	// 	QKrbizFeedback: require ('./images/HR/Engagement/Feedback.png'),
	// 	QKrbizInternalNotes: require ('./images/HR/Engagement/InternalNotes.png'),
	// 	QKrbizChat: require ('./images/HR/Engagement/Chat.png'),
	// },
	expense:{
		QkrExpenseHeader: require ('./images/Qkrexpense/QkrexpenseHearder.jpg'),

		ForEmployees: require ('./images/Qkrexpense/ForEmployees.png'),
		ForManagers: require ('./images/Qkrexpense/ForManagers.png'),
		ForOrganization: require ('./images/Qkrexpense/ForOrganization.png'),

		AutomateExpense: require ('./images/Qkrexpense/AutomateExpense.png'),
		ExpenseRequest: require ('./images/Qkrexpense/ExpenseRequest.svg').default,
		ExpenseApproval: require ('./images/Qkrexpense/ExpenseApproval.svg').default,
		PaymentExpenses: require ('./images/Qkrexpense/PaymentExpenses.svg').default,

		FeaturesHead: require ('./images/Qkrexpense/FeaturesHead.png'),
		FeaturesHead2: require ('./images/Qkrexpense/FeaturesHead2.png'),
		ExpenseManagement: require ('./images/Qkrexpense/ExpenseManagement.svg').default,
		TravelManagement: require ('./images/Qkrexpense/TravelManagement.svg').default,
		FieldVisitsMileage: require ('./images/Qkrexpense/FieldVisitsMileage.svg').default,
		AdvancesDisbursement: require ('./images/Qkrexpense/AdvancesDisbursement.svg').default,
		ProjectExpenses: require ('./images/Qkrexpense/ProjectExpenses.svg').default,
		ApprovalWorkflows: require ('./images/Qkrexpense/ApprovalWorkflows.svg').default,
		IntelligentDashboards: require ('./images/Qkrexpense/IntelligentDashboards.svg').default,
		ActionableAnalytics: require ('./images/Qkrexpense/ActionableAnalytics.svg').default,

        ExpenseClaims: require ('./images/Qkrexpense/ExpenseClaims.png'),
		MileageTracking: require ('./images/Qkrexpense/MileageTracking.png'),
		TravelDesk: require ('./images/Qkrexpense/TravelDesk.png'),

		AdvancesExpensReimbursements: require ('./images/Qkrexpense/AdvancesExpensReimbursements.png'),
		ConfigurableWorkflows: require ('./images/Qkrexpense/ConfigurableWorkflows.png'),

		ProjectExpense: require ('./images/Qkrexpense/ProjectExpenses.png'),
		Analytics: require ('./images/Qkrexpense/Analytics.png'),
    },
	desk:{
		QkrdeskHearder: require ('./images/Qkrdesk/QkrdeskHearder.png'),

		Deskfeatures_1: require ('./images/Qkrdesk/Deskfeatures_1.png'),
		Deskfeatures_2: require ('./images/Qkrdesk/Deskfeatures_2.png'),
		TicketsManagement: require ('./images/Qkrdesk/TicketsManagement.svg').default,
		ServiceContractManagement: require ('./images/Qkrdesk/ServiceContractManagement.svg').default,
		ManageFieldVisits: require ('./images/Qkrdesk/ServiceContractManagement.svg').default,
		WebBasedAccess: require ('./images/Qkrdesk/WebBasedAccess.svg').default,
		ProcessManagement: require ('./images/Qkrdesk/ProcessManagement.svg').default,
		ActIntelligence: require ('./images/Qkrdesk/ActIntelligence.svg').default,
		GrowWithoutGrowing: require ('./images/Qkrdesk/GrowWithoutGrowing.svg').default,
		LeadCustomers: require ('./images/Qkrdesk/LeadCustomers.svg').default,
		ConnectingBusiness: require ('./images/Qkrdesk/ConnectingBusiness.svg').default,
		Ticketing_bg: require ('./images/Qkrdesk/Ticketing_bg.png'),
		ConnectingDepartment: require ('./images/Qkrdesk/ConnectingDepartment.png'),
		StreamlineConversations: require ('./images/Qkrdesk/StreamlineConversations.svg').default,
		CollaborateBetter: require ('./images/Qkrdesk/CollaborateBetter.svg').default,
		StayProductive: require ('./images/Qkrdesk/StayProductive.svg').default,
	},
	sales:{
		QkrsalesHearder: require ('./images/qkrsales/QkrsalesHearder.png'),
		SalesOrder_1: require ('./images/qkrsales/SalesOrder_1.png'),
		SalesOrder_2: require ('./images/qkrsales/SalesOrder_2.png'),
		SalesOrder_3: require ('./images/qkrsales/SalesOrder_3.png'),
		// WeContinueFaceChallenges: require ('./images/qkrsales/WeContinueFaceChallenges.png'),
		// Workflow: require ('./images/qkrsales/Workflow.svg').default,
		// Notifications: require ('./images/qkrsales/Notifications.svg').default,
		// Reminders: require ('./images/qkrsales/Reminders.svg').default,
		// Alerts: require ('./images/qkrsales/Alerts.svg').default,
		// PerformanceView: require ('./images/qkrsales/PerformanceView.svg').default,
		// BusinessIntelligence: require ('./images/qkrsales/BusinessIntelligence.svg').default,
		// HighlyConfigurable:require ('./images/qkrsales/HighlyConfigurable.svg').default,
		// AuditTrail:require ('./images/qkrsales/AuditTrail.svg').default,
		// DocumentManagement:require ('./images/qkrsales/DocumentManagement.svg').default,
		TakeHassleOut: require ('./images/qkrsales/TakeHassleOut.svg').default,
		UnlockCapital: require ('./images/qkrsales/UnlockCapital.svg').default,
		SpeedCollection: require ('./images/qkrsales/SpeedCollection.svg').default,
		BuildStrongProcess: require ('./images/qkrsales/BuildStrongProcess.svg').default,
		GetCrystal: require ('./images/qkrsales/GetCrystal.svg').default,
		BuildAccountability: require ('./images/qkrsales/BuildAccountability.svg').default,
		ImprovePerformance: require ('./images/qkrsales/ImprovePerformance.svg').default,
		ChnageHabits: require ('./images/qkrsales/ChnageHabits.svg').default,	
		SalesManagementHeader: require ('./images/qkrsales/SalesManagementHeader.png'),
		AccountsReceivableHeader: require ('./images/qkrsales/AccountsReceivableHeader.png'),
		OrderExecutionHeader: require ('./images/qkrsales/OrderExecutionHeader.png'),
		BillingPaymentHeader: require ('./images/qkrsales/BillingPaymentHeader.png'),
		Invoicing: require ('./images/qkrsales/Invoicing.png'),
		SalesPerformanceHeader: require ('./images/qkrsales/SalesPerformanceHeader.png'),
		TenderDepositHeader: require ('./images/qkrsales/TenderDepositHeader.png'),
		SalesEffortlessSystem: require ('./images/qkrsales/SalesEffortlessSystem.png'),
		SalesQkrbizPrimed: require ('./images/qkrsales/SalesQkrbizPrimed.png'),
		AccountsReceivableHead:require ('./images/qkrsales/AccountsReceivable/AccountsReceivableHeader.png'),
		TrackPayments:require ('./images/qkrsales/AccountsReceivable/TrackPayments.png'),
		AllinOnePlatform:require ('./images/qkrsales/AccountsReceivable/AllinOnePlatform.png'),
		MinimizeForecast:require ('./images/qkrsales/AccountsReceivable/MinimizeForecast.png'),     
	},
	Qkrtask:{
		QkrtaskHeader: require ('./images/Qkrtask/QkrtaskHeader.png'),
		EffortlessExperienceBody:require('./images/Qkrtask/EffortlessExperienceBody.png'),
		HistoryBody:require('./images/Qkrtask/HistoryBody.png'),
		TaskViewBody:require('./images/Qkrtask/TaskViewBody.png'),
		Reporting:require('./images/Qkrtask/Reporting.png'),
		ImportantTasks:require('./images/Qkrtask/ImportantTasks.png'),
		Renewals:require('./images/Qkrtask/Renewals.png'),
		Compliances:require('./images/Qkrtask/Compliances.png'),
		StartDates:require('./images/Qkrtask/StartDates.png'),
		RecurringTask:require('./images/Qkrtask/RecurringTask.png'),
		StopAfter:require('./images/Qkrtask/StopAfter.png'),
		AssignmentDate:require('./images/Qkrtask/AssignmentDate.png'),
		CustomFields:require('./images/Qkrtask/CustomFields.png'),
		Priorities:require('./images/Qkrtask/Priorities.png'),
		HierarchyBasedAccess:require('./images/Qkrtask/HierarchyBasedAccess.png'),
		RemindersForEverything:require('./images/Qkrtask/RemindersForEverything.png'),
		ViewHistory:require('./images/Qkrtask/ViewHistory.png'),
		WorkNotDoneClosure:require('./images/Qkrtask/WorkNotDoneClosure.png'),
		Category:require('./images/Qkrtask/Category.png'),
		Filters:require('./images/Qkrtask/Filters.png'),
	},
	QkrTenders:{
		Qkrtenderheader: require ('./images/Qkrtenders/Qkrtenderheader.png'),
		BiddingStatus: require ('./images/Qkrtenders/BiddingStatus.png'),
		TendersFeature_1: require ('./images/Qkrtenders/TendersFeature_1.png'),
		TendersFeature_2: require ('./images/Qkrtenders/TendersFeature_2.png'),
		DepositRequests: require ('./images/Qkrtenders/DepositRequests.svg').default,
		BankProcessing: require ('./images/Qkrtenders/BankProcessing.svg').default,
		BidManagement: require ('./images/Qkrtenders/BidManagement.svg').default,
		CollectDeposits: require ('./images/Qkrtenders/CollectDeposits.svg').default,
		ExtensionsAmendments: require ('./images/Qkrtenders/ExtensionsAmendments.svg').default,
		PerformanceMonitoring: require ('./images/Qkrtenders/PerformanceMonitoring.svg').default,
		GenerateLetters: require ('./images/Qkrtenders/GenerateLetters.svg').default,
		TendersEffortlessSystem: require ('./images/Qkrtenders/TendersEffortlessSystem.png'),
		TendersQkrbizPrimed: require ('./images/Qkrtenders/TendersQkrbizPrimed.png'),
		DespiteOurGrowing: require ('./images/Qkrtenders/DespiteOurGrowing.png'),
	},
	QkrProjects:{
		Qkrprojecthearder: require ('./images/Qkrprojects/Qkrprojecthearder.png'),
		QkrprojecFeature_1: require ('./images/Qkrprojects/QkrprojecFeature_1.png'),
		WorkOrder: require ('./images/Qkrprojects/WorkOrder.svg').default,
		MilestoneManagement: require ('./images/Qkrprojects/MilestoneManagement.svg').default,
		DocumentManagement: require ('./images/Qkrprojects/DocumentManagement.svg').default,
		ApprovalWorkflow: require ('./images/Qkrprojects/ApprovalWorkflow.svg').default,
		BudgetingExpens: require ('./images/Qkrprojects/BudgetingExpens.svg').default,
		ManpowerExpenses: require ('./images/Qkrprojects/ManpowerExpenses.svg').default,
		RenewalManagement: require ('./images/Qkrprojects/RenewalManagement.svg').default,
	}
};

export default ImagesUrl;
