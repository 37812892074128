import ImagesUrl from "../../assets/Index";

export const HeaderMenuData = [
    {
        id:1,
        title:"Qkrbiz Apps",
        head1:"Products",
        head2:"Platform Features",
        PlatformFeaturData : [
            {
                id:1,
                url:"/views",
                classNames:"pfview",
                imgUrls:ImagesUrl.platformfeatures.PfView,
                title:"Views", 
                headclr:"text-pink",
                desc:"Visualize your work the way you want",
                readMore:"Your Data, Your View"
            },
            {
                id:2,
                url:"/features",
                classNames:"pfSpreadsheetFeatures",
                imgUrls:ImagesUrl.platformfeatures.PfpreadsheetFeatures,
                title:"Spreadsheet Features",
                headclr:"text-warning",
                desc:"Comfort of familiar spreadsheet interface",
                readMore:"Uncover Spreadsheet Features"
            },
            {
                id:3,
                url:"/alertnotification",
                classNames:"pfAlertsNotification",
                imgUrls:ImagesUrl.platformfeatures.PfAlertsNotification,
                title:"Alerts & Notifications",
                headclr:"text-danger",
                desc:"Never miss a task or deadline",
                readMore:"Get Notified Instantly"
            },
            {
                id:4, 
                url:"/workflow",
                classNames:"pfWorkflowAutomation",
                imgUrls:ImagesUrl.platformfeatures.PfAutomationWorkflow,
                title:"Workflow Automation",
                headclr:"text-magenta",
                desc:"Configure workflows as per your policies",
                readMore:"Configure Your Workflows"
            },
            {
                id:5,
                url:"/customizations",
                classNames:"pfCustomizations",
                imgUrls:ImagesUrl.platformfeatures.PfCustomizations,
                title:"Customizations",
                headclr:"text-magenta",
                desc:"Tailor to fit your needs",
                readMore:"Make It Yours"
            },
            {
                id:6,
                url:"/documents",
                classNames:"pfDocuments",
                imgUrls:ImagesUrl.platformfeatures.PfDocuments,
                title:"Documents",
                headclr:"text-pink",
                desc:"Integrated Document management",
                readMore:"Manage Documents Seamlessly"
            },
            {
                id:7,
                url:"/security",
                classNames:"pfSecurity",
                imgUrls:ImagesUrl.platformfeatures.PfSecurity,
                title:"Security",
                headclr:"text-warning",
                desc:"Protecting your data is our obsession",
                readMore:"Secure Your Data"
            },
            {
                id:8,
                url:"/bianalytics",
                classNames:"pfBIAnalytics",
                imgUrls:ImagesUrl.platformfeatures.PfBIAnalytics,
                title:"BI & Analytics",
                headclr:"text-danger",
                desc:"Actionable Insights at your finger tips",
                readMore:"Get Actionable Insights"
            },
            {
                id:9,
                url:"/boardview",
                classNames:"pfBoards",
                imgUrls:ImagesUrl.platformfeatures.PfBoards,
                title:"Boards",
                headclr:"text-warning",
                desc:"Built your own Kanban boards",
                readMore:"Organize with Boards"
            }
            // {
            //     id:10,
            //     url:"/mobileapp",
            //     classNames:"pfMobileApp",
            //     imgUrls:ImagesUrl.platformfeatures.PfMobileApp,
            //     title:"Mobile App",
            //     headclr:"text-magenta",
            //     desc:"Stay connected in office or outside",
            // },
            // {
            //     id:11,
            //     url:"/listview",
            //     classNames:"pfBoards",
            //     imgUrls:ImagesUrl.platformfeatures.PfBoards,
            //     title:"List View",
            //     headclr:"text-warning",
            //     desc:"Built your List view",
            // } 
        ],
        ProductMenuData : [
            {
                id:1,
                urls:"/hrms-software",
                productClass:"qkrhr",
                title:"QkrHR",
                desc:"HRMS Software",
                readMore:"Unleash Workplace Success",
                ApsDesc:"Empowering Digital Teams, Enhancing Human Connections",
                heading:"All-in-One HRMS Software", 
                headDesc:"Unlock the Full Potential of Your Workforce by Automating HR at Every Step",
                IsProduct:"Hrms",
                key: true,
                ProductList:[ 
                    {
                        id:1,
                        urls:"core-hr",
                        iconClass:"QkrHR",
                        title:"Core HR", 
                        desc:"Create a centralized, connected, and modern work environment aligned with your business dynamics",
                        readMore:"Effortless HR",
                        heading:"Core HR Features", 
                        headDesc:"Enhancing Your Workforce Management",        
                        IsProduct:"Corhr",
                        ListData:[ 
                            {
                                id:1,
                                urls:"employee-management",
                                iconClass:"employee-database",
                                title:"Employee Database",
                                desc:"Centralized Data, Empowered Decisions",
                                readMore:" Centralize to Optimize"
                            },
                            {
                                id:2,
                                urls:"employee-self-service-portal",
                                title:"ESS Portal",
                                iconClass:"ess-portal",
                                desc:"Self-Service Simplified, Employee Empowerment Amplified",
                                readMore:"Step Inside",
                            },
                            {
                                id:3,
                                // urls:"document-management",
                                urls:"hr-document-management-system",
                                iconClass:"core-documents",
                                title:"Documents",
                                desc:"Paperless Efficiency, Seamless Compliance",
                                readMore:"Go Paperless", 
                            },
                            {
                                id:4,
                                urls:"hr-workflow-management",
                                iconClass:"hr-workflows",
                                title:"Workflows",
                                desc:"Automate Processes, Accelerate Progress",
                                readMore:"Optimize Every Step", 
                            },
                            {
                                id:5,
                                urls:"hr-data-analytics",
                                iconClass:"hr-reports",
                                title:"HR Reports",
                                desc:"Data-Driven Insights, Strategic HR Foresight",
                                readMore:"Reveal the Data", 
                            },  
                        ]
                    },
                    {
                        id:2,
                        urls:"field-force-automation",
                        iconClass:"FieldForceAutomation",
                        title:"Field Force Automation",
                        desc:"Get a granular control over tracking field movements seamlessly integrated with attendance management",
                        readMore:"Track Field Teams Seamlessly",
                        heading:"",
                        headDesc:"",
                        IsProduct:"FieldForceAutomation",
                        ListData:[ 
                            {
                                
                            }
                            // {
                            //     id:1,
                            //     urls:"employee-management",
                            //     iconClass:"employee-database",
                            //     title:"Employee Database",
                            //     desc:"Centralized Data, Empowered Decisions",
                            //     readMore:" Centralize to Optimize"
                            // },
                            // {
                            //     id:2,
                            //     urls:"employee-self-service-portal",
                            //     title:"ESS Portal",
                            //     iconClass:"ess-portal",
                            //     desc:"Self-Service Simplified, Employee Empowerment Amplified",
                            //     readMore:"Step Inside",
                            // },
                        ]
                    },
                    {
                        id:3,
                        urls:"leave-and-attendance-management",
                        iconClass:"TimeAttendance",
                        title:"Time and Attendance",
                        desc:"Tailor attendance and leave policies to fit diverse job profiles",
                        readMore:"Smart Attendance",
                        heading:"Time and Attendance Features",
                        headDesc:"Tailor attendance and leave policies to fit diverse job profiles",
                        IsProduct:"TimeAttendance",
                        ListData:[
                            {
                                id:1,
                                urls:"time-and-attendance-system", 
                                iconClass:"attendance-management",
                                title:"Attendance Management",
                                desc:"Automate attendance recording and instantly access data",
                                readMore:"Track Instantly"
                            }, 
                            {
                                id:2,
                                urls:"leave-management-system",  
                                iconClass:"leave-management",
                                title:"Leave Management",
                                desc:"Automate leave requests, approvals, and policy enforcement",
                                readMore:"Simplify Leaves"  
                            },
                            {
                                id:3,
                                urls:"shift-management-system", 
                                iconClass:"shift-management",
                                title:"Shift Management",
                                desc:"Easily assign shifts, track hours, and handle shift changes.",
                                readMore:"Master Shifts"  
                            },
                        ]
                    },
                    {
                        id:4, 
                        urls:"payroll-management-system",
                        iconClass:"Payroll",
                        title:"Payroll Management",
                        desc:"Tackle payroll complexity, ensuring compliance and accurate tax calculations through configurable templates with ease",
                        readMore:"Perfect Payroll Every Time",
                        heading:"",
                        headDesc:"",
                        IsProduct:"payrollManagement",
                        ListData:[
                                {}
                        ]
                    },
                    {
                        id:5,
                        urls:"expense-management",
                        iconClass:"Expenses",
                        title:"Expenses Management",
                        desc:"Unify travel, advances, and expenses with field movements and project budgets",
                        readMore:"Manage Expenses Smartly",
                        heading:"",
                        headDesc:"",
                        IsProduct:"ExpensesManagement",
                        ListData:[ 
                            { }
                        ]
                    },
                    {
                        id:6,
                        urls:"employee-engagement",
                        iconClass:"Engagement",
                        title:"Employee Engagement",
                        desc:"Foster a vibrant workplace by enhancing meaningful employee interactions.",
                        readMore:"Spark Team Spirit",
                        heading:"",
                        headDesc:"",
                        IsProduct:"EmployeeEngagement",
                        ListData:[ 
                            { }
                        ]
                    },
                    {
                        id:7,
                        urls:"tasks",
                        iconClass:"Tasks",
                        title:"Task Management",
                        desc:"Never miss a beat with QkrHR's intelligent task management.",
                        readMore:"Stay on Top of Tasks",
                        heading:"",
                        headDesc:"",
                        IsProduct:"Tasks",
                        ListData:[ 
                            { }
                        ]
                    },
                    // {
                    //     id:7,
                    //     urls:"qkrhr-pricing",
                    //     iconClass:"",
                    //     title:"",
                    //     desc:"",
                    //     IsProduct:"HrmsPricing",
                    //     ListData:[ 
                    //         { }
                    //     ]
                    // },   
                ]   
            },
            {
                id:2,
                urls:"/qkrvisit",
                productClass:"qkrvisit",
                title:"Qkrvisit",
                desc:"Field Force Management",
                readMore:"Field Force, Simplified",
                ApsDesc:"Because Your Workforce Knows No Boundaries",
                heading:"",
                headDesc:"",
                IsProduct:"visit",
                key: true,
                ProductList:[ 
                    {
                        id:1,
                        urls:"",
                        iconClass:"",
                        title:"", 
                        desc:"",
                        IsProduct:"",
                        ListData:[ 
                            { }
                        ]
                    }, 
                ] 
            },
            {
                id:3,
                urls:"/qkrexpense",
                productClass:"qkrexpense",
                title:"Qkrexpense",
                desc:"Advances, Travel & Expenses",
                readMore:"Control Business Spending",
                ApsDesc:"Streamline Spending, Simplify Reimbursement",
                heading:"",
                headDesc:"",
                IsProduct:"expense",
                key: true,
                ProductList:[ 
                    {
                        id:1,
                        urls:"",
                        iconClass:"",
                        title:"", 
                        desc:"",
                        IsProduct:"",
                        ListData:[ 
                            { }
                        ]
                    }, 
                ]
            },
            {
                id:4, 
                urls:"/qkrdesk",
                productClass:"qkrdesk",
                title:"Qkrdesk",
                desc:"Manage Customer Support",
                readMore:"Elevate Customer Support",
                ApsDesc:"Bridging Clients and Support, Seamlessly",
                heading:"",
                headDesc:"",
                IsProduct:"desk",
                key: true,
                ProductList:[ 
                    {
                        id:1,
                        urls:"",
                        iconClass:"",
                        title:"", 
                        desc:"",
                        IsProduct:"",
                        ListData:[ 
                            { }
                        ]
                    }, 
                ]
            },
            {
                id:5,
                urls:"/qkrsales",
                productClass:"qkrsales",
                title:"Qkrsales",
                desc:"Sales and AR Management",
                readMore:"Boost Cashflow Now",
                ApsDesc:"Maximizing Revenue from Product to Service, Order to Cash",
                heading:"",
                headDesc:"",
                IsProduct:"sales",
                key: true,
                ProductList:[ 
                    {
                        id:1,
                        urls:"salesmanagement",
                        iconClass:"SalesManagement",
                        title:"Sales Featurs", 
                        desc:"",
                        IsProduct:"Product",
                        ListData:[ 
                            // {
                            //     id:1,
                            //     urls:"salesmanagement",
                            //     iconClass:"SalesManagement",
                            //     title:"Sales Management", 
                            //     desc:"With our workflow-based Sales Order management system, streamline your sales process and get complete control of the order execution process! Order management is now simpler than ever thanks to our user-friendly platform.",
                            // },
                            {
                                id:1,
                                urls:"accountsreceivable",
                                iconClass:"AccountsReceivable",
                                title:"Accounts Receivable",
                                desc:"Make sure that every payment is received on schedule and that you have complete visibility into every receivable at a granular level. No more losses brought on by unrecovered payments!",
                            },
                            {
                                id:2,
                                urls:"billingpayment",
                                iconClass:"BillingPayment",
                                title:"Billing & Payment",
                                desc:"Keeping track of customers' complex billing and payment terms can be a daunting task, but it doesn't have to be. With Qkrsales, manage complex customer billing schedules and payments at every step along the way with ease.",
     
                            },
                            {
                                id:3, 
                                urls:"orderexecution",
                                iconClass:"OrderExecution",
                                title:"Order Execution",
                                desc:"Track important deliverables with Milestones, such as delivery, installation, invoicing, and payment, to keep your projects moving forward. Proactively monitor deadlines with warnings to help prevent delays and Liquidated Damages!",
                            },
                            {
                                id:4,
                                urls:"invoicing",
                                iconClass:"Invoicing",
                                title:"Invoicing",
                                desc:"Keep on top of billing and never let a due date slip past you with proactive alerts. Streamline your processes by mapping billing terms of every order and track them from start to finish!",
                            },
                            {
                                id:5,
                                urls:"tenderdeposit",
                                iconClass:"TenderDeposit",
                                title:"Tender Deposit",
                                desc:"Ensure timely submission and recovery of all deposits such as Security Deposits (SD), Performance Bank Guarantees (PBG), and Earnest Money Deposits (EMD). Make sure that all tender terms are fully complied with meticulous recording from the order stage.",
                            },
                            {
                                id:6,
                                urls:"salesperformance",
                                iconClass:"SalesPerformance",
                                title:"Sales Performance",
                                desc:"With real-time performance tracking, your sales team is empowered to maximize their success. Monthly updates keep everyone informed and striving for excellence!",
                            },
                            // {
                            //     id:8,
                            //     urls:"documentmanagement",
                            //     iconClass:"DocumentManagement",
                            //     title:"Document Management",
                            //     desc:"Store and retrieve all of your documents with ease! Accessing all of the documents generated at various phases of order processing is now simple and quick.",
                            // },
                            // {
                            //     id:9,
                            //     urls:"businessmanagement",
                            //     iconClass:"BusinessManagement",
                            //     title:"Business Management",
                            //     desc:"Gain greater control of your business with seamless integrations. Streamline processes by integrating Qkrtender, Qkrprojects, QkrHR, Qkrinventory and Qkrexpense into one unified platform.",
                            // },

                        ]
                    } 
                ]
            },
            {
                id:6,
                urls:"/qkrtenders",
                productClass:"qkrtenders",
                title:"Qkrtenders",
                desc:"Tender Bidding & Deposits",
                readMore:"Win More Bids",
                ApsDesc:"Streamline Bidding, Manage Deposits",
                heading:"",
                headDesc:"",
                IsProduct:"tenders",
                key: true,
                ProductList:[ 
                    {
                        id:1,
                        urls:"",
                        iconClass:"",
                        title:"", 
                        desc:"",
                        IsProduct:"",
                        ListData:[ 
                            { }
                        ]
                    } 
                ]
            },
            {
                id:7,
                urls:"/qkrtasks",
                productClass:"qkrtask",
                title:"Qkrtasks",
                desc:"Task Management",
                readMore:"Get More Done",
                ApsDesc:"Transforming Tasks into Triumphs",
                heading:"",
                headDesc:"",
                IsProduct:"Tasks",
                key: true,
                ProductList:[ 
                    {
                        id:1,
                        urls:"",
                        iconClass:"",
                        title:"", 
                        desc:"",
                        IsProduct:"",
                        ListData:[ 
                            { }
                        ]
                    }
                ]
            },
            {
                id:8,
                urls:"/qkrprojects",
                productClass:"qkrprojects",
                title:"Qkrprojects",
                desc:"Collaborative Work Management",
                readMore:"Power Your Projects",
                ApsDesc:"Streamline Expenses, Track Progress, Achieve Milestones",
                heading:"",
                headDesc:"",
                IsProduct:"projects",
                key: true,
                ProductList:[ 
                    {
                        id:1,
                        urls:"",
                        iconClass:"",
                        title:"", 
                        desc:"",
                        IsProduct:"",
                        ListData:[ 
                            { }
                        ]
                    }
                ]
            },
        ]
    }  
]
