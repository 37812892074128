import React,{Fragment, useRef} from 'react';
import './Home.scss';
import Container from 'react-bootstrap/Container';
import { Link, useLocation } from 'react-router-dom';
import Clients from '../../components/Clients/Clients';
import ClientDetails from "../../components/ClientDetails/ClientDetails.js";
import Cards from '../../components/Cards/Cards.js';
import TabsScroll from '../../components/TabsScroll/TabsScroll.js';
import { TabScrollData } from "../../components/TabsScroll/TabsScrollData.js";
import { EffortlessUserData, HomeProductData, SecureAccData } from './HomeData.js';
import { HeaderMenuData } from '../../shared/Header/HeaderMenuData.js';
import RouteSpecificMeta from '../../utility/Seo/RouteSpecificMeta.js';
import { QkrbizSchemaData } from './QkrbizSchemaData.js';
import ImagesUrl from '../../assets/Index.js';

function Home() {

    const locationUrls = useLocation();
    const pageUrls  = `${window.location.origin}${locationUrls.pathname}`;
    const socialImage = ImagesUrl.QkrbizOgurl;
    
    // const locationUrls = useLocation();
    // const pageUrls = `${window.location.origin}${locationUrls.pathname.endsWith("/") ? locationUrls.pathname.slice(0, -1) : locationUrls.pathname}`;
    // console.log('home url',pageUrls);

    const AppsRef = useRef(null);
    const handleClickApps = (event) => {
        event.preventDefault();
        const offset = AppsRef.current.offsetTop - 100;
        window.scrollTo({
        top:offset,
        behavior :'smooth'
        });
        // AppsRef.current?.scrollIntoView({behavior: 'smooth'});
    };

  return (
    <>
        <RouteSpecificMeta
        title="QkrBiz | Best Business Management Software | ERP Cloud Solutions"
        description="QkrBiz offers all-in-one business management software tailored for small and mid-sized businesses. Streamline your operations with our comprehensive solutions for inventory, customer management, HR, and field services. Try it now!"
        canonical={pageUrls}
        image={socialImage}
        SchemaData={QkrbizSchemaData} 
        />
        <div className="hero-top-banner">
            <Container>
                <div className="row justify-content-between">
                    {HomeProductData.map((headText, headIndex)=>(
                        <div className="col-lg-7 col-xl-7 col-xxl-5 banner-heading" key={headIndex}>
                            <h1><span dangerouslySetInnerHTML={{ __html: headText.title.replace("className='", "class='")}}></span></h1>
                            <p>{headText.desc}</p>
                            <Link to={headText.link} className="btn btn-outline-magenta">Free Trial </Link>
                
                            <div className="bottom-img">
                                <img className="img-fluid" src={headText.imgUrl} alt={headText.title.replace(/<\/?[^>]+(>|$)/g, "")} />
                            </div>
                        </div>
                    ))}
                    <div className="col-lg-5 col-xl-4 col-xxl-4 apps">
                        <div className="card">
                            <div className="card-body qkrModule"> 
                                {HeaderMenuData.map((headerApp, appIndex)=>(
                                    <Fragment key={appIndex}>
                                            <p className="apps-heading">{headerApp.title}</p>
                                            {headerApp.ProductMenuData.slice(0, 6).map((appsMenu, appIndex)=>(
                                                <Link to={appsMenu.urls} className="d-flex" key={appIndex}>
                                                    <span className="flex-shrink-0">
                                                        <span className={`qkrimg ${appsMenu.productClass}`}></span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <h5>{appsMenu.title}</h5>   
                                                        <p className="right-arrow">{appsMenu.desc}</p>
                                                    </span>
                                                </Link>
                                            ))}
                                    </Fragment>
                                ))}
                            </div>
                            <div className="card-footer">
                                <Link to="#" onClick={handleClickApps} className="allproducts text-magenta right-arrow">All Product</Link>
                            </div>
                            
                        </div>
                    </div>
                </div> 
            </Container>        
        </div>
        <div className="apps-section" ref={AppsRef}>
            <Container>
                {HeaderMenuData.map((header, hIndex)=>(
                    <Fragment key={hIndex}>
                        <div className="heading-title text-center">
                            <h2 className="under-line">{header.title}</h2> 
                        </div>
                        <div className="row justify-content-center align-items-center mt-3">
                            <div className="col-lg-12 col-xl-10 col-xxl-9">
                                <div className="row qkrModule">
                                        {header.ProductMenuData.map((proItem, proIndex)=>(
                                            <div className="col-md-6 col-lg-4" key={proIndex}>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <span className={`qkrimg ${proItem.productClass}`}></span>
                                                        <h4>{proItem.title}</h4>
                                                        <p>{proItem.ApsDesc}</p>
                                                    </div>
                                                    <div className="card-footer">
                                                        <Link to={proItem.urls} className="text-magenta arrowLink">{proItem.readMore}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </Fragment>
                ))}
                {/* <div className="heading-title text-center">
                    <h2 className="under-line">Qkrbiz Apps</h2> 
                </div> 
                <div className="row justify-content-center align-items-center mt-3">
                    <div className="col-lg-12 col-xl-10 col-xxl-9">
                        <div className="row qkrModule">
                           {HomeProductData.map((product, proIndex)=>(
                                <Fragment key={proIndex}>
                                    {product.AppMenue.map((app, appIndex)=>(
                                        <div className="col-md-6 col-lg-4" key={appIndex}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <span className={`qkrimg ${app.productClass}`}></span>
                                                    <h4>{app.title}</h4>
                                                    <p>{app.desc}</p>
                                                </div>
                                                <div className="card-footer">
                                                    <Link to={app.urls} className="text-magenta arrowLink">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Fragment>   
                            ))}
                        </div>
                    </div>
                </div> */}
            </Container>
        </div>
        <TabsScroll TabScrollData={TabScrollData} addCls="tabscroll-view" count1={9} count2={5} count3={4} count4={3} count5={2}/>
        <div className="box effortless-user bg-gray">
            <Container>
                    {EffortlessUserData.map((Effortless, EffortIndex)=>(
                        <Fragment key={EffortIndex}>
                            <div className="row justify-content-center align-items-center mb-5">
                                <div className="col-lg-10 col-xl-8 col-xxl-7 text-center">
                                    <div className="heading-title text-center">
                                        <h2 className="under-line mb-3">{Effortless.title}</h2> 
                                    </div>
                                    <p className="fn-4"><span dangerouslySetInnerHTML={{ __html: Effortless.desc.replaceAll("className='", "class='")}}></span></p> 
                                </div>
                            </div>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-12 col-xl-11 col-xxl-11">
                                    <div className="row rows">
                                        {Effortless.EffortlessList.map((test, testIndex)=>(
                                            <div className="col-lg-4" key={testIndex}>
                                                <Cards
                                                imgUrl={test.imgUrl}
                                                alts={test.title}
                                                head={test.title}
                                                headDesc={test.subTitle}
                                                desc={test.desc}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Fragment>    
                    ))}
            </Container>
        </div>
        <div className="accountable">
            <Container>
                {SecureAccData.map((Sectext, SecIndex)=>(
                    <Fragment key={SecIndex}>
                        <div className="row justify-content-center align-items-center mb-5 mt-3">
                            <div className="col-lg-10 col-xl-8 col-xxl-7 text-center">
                                <div className="heading-title text-center">
                                    <h2 className="under-line under-line mb-4"><span dangerouslySetInnerHTML={{ __html: Sectext.title.replaceAll("className='", "class='")}}></span></h2> 
                                </div>
                                <p className="fn-3 mb-4">{Sectext.desc}</p> 
                            </div>
                        </div>
                        {Sectext.SecureAccList.map((SecList, SeclistIndex, array)=>{
                        const lastIndex = array.length - 1;
                            return (
                                <Fragment key={SeclistIndex}>
                                    {SecList.cls === "text-right" ? (
                                            <div className="row">
                                                <div className="col-lg-6" >
                                                    <div className="d-flex row-reverse">
                                                        <div className={`flex-shrink-1 ${SecList.cls}`}>
                                                            <h4 className="subheading">{SecList.title}</h4>
                                                            <p className="desc">{SecList.desc}</p>
                                                            <div className="line"></div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="secure_icon">
                                                                <img src={SecList.imgUrl} alt={SecList.title} />
                                                            </div>
                                                        </div>
                                                    </div>	
                                                </div>
                                        </div>
                                        ):(
                                        <div className="row">
                                            <div className="col-lg-6"></div>
                                            <div className="col-lg-6">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <div className="secure_icon">
                                                            <img src={SecList.imgUrl} alt={SecList.title} />
                                                        </div>
                                                    </div>
                                                    <div className={`flex-shrink-1 ${SecList.cls}`}>
                                                        <h4 className="subheading">{SecList.title}</h4>
                                                        <p className="desc">{SecList.desc}</p>
                                                        {SeclistIndex !== lastIndex ? (
                                                            <div className="line"></div>
                                                        ):null}
                                                    </div>
                                                </div>	
                                            </div>
                                        </div>
                                    )}
                                </Fragment>
                            );
                        })}
                    </Fragment>  
                ))}
            </Container>
        </div>
        <ClientDetails/>
        <Clients />
    </>
  )
}

export default Home;