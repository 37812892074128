import React from 'react';
import ReactDOM from 'react-dom/client'; 
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/scss/_index.scss';
import './index.scss';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'production') {
  const currentUrl = window.location.href;
  let newUrl = currentUrl;

  // Normalize URL to ensure it starts with 'https://www.'
  if (currentUrl.startsWith('http://')) {
    newUrl = currentUrl.replace(/^http:\/\//, 'https://www.');
  } else if (currentUrl.startsWith('https://') && !currentUrl.startsWith('https://www.')) {
    newUrl = currentUrl.replace(/^https:\/\//, 'https://www.');
  } else if (currentUrl.startsWith('www.')) {
    newUrl = currentUrl.replace(/^www./, 'https://www.');
  }

  // Remove redundant 'www.' prefixes or other anomalies
  const urlPattern = /^https:\/\/www\.(www\.)*/;
  newUrl = newUrl.replace(urlPattern, 'https://www.');

  // Ensure no trailing domain duplication (e.g., '.com.com')
  newUrl = newUrl.replace(/(\.\w+)\1+$/, '$1');

  // Redirect only if the new URL is different from the current one
  if (currentUrl !== newUrl) {
    window.location.href = newUrl;
  }
}


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
      <HelmetProvider>
          <Router future={{
            v7_startTransition: true,
            v7_relativeSplatPath: true,
          }}>
            <App />
          </Router>   
      </HelmetProvider>
);

reportWebVitals();
